import axios from '@/config/httpConfig'


//个人搜索对象设置页
export function getObjectSetting(data) {
  return axios.post('/globalSearch/getObjectSetting', data)
}
//获取全局搜索结果(按对象分开查)
export function getSingleGlobalSearchResult(data) {
  return axios.post('/globalSearch/getGlobalSearchSingleResult', data)
}
//获取全局搜索结果
export function getGlobalSearchResult(data) {
  return axios.post('/globalSearch/getGlobalSearchResult', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}
//获取单个对象信息及表头信息
export function getListFieldInfo(data) {
  return axios.post('/globalSearch/getListFieldInfo', data)
}
//获取最近查看记录
export function getRecentRecords(data) {
  return axios.post('/globalSearch/getRecentRecords', data)
}
//保存全局搜索对象设置
export function saveObjectSetting(data) {
  return axios.post('/globalSearch/saveObjectSetting', data)
}
// 全局搜索高级筛选
export function getGlobalSearchSingleResult(data) {
  return axios.post("/KnowledgeArticle/getGlobalSearchSingleResult", data);
}
// 查询语言接口
export function queryLanguage(data) {
  return axios.post("/knowledgeTranslate/queryLanguage", data);
}
//查询全局搜索对象数量
export function getGlobalSearchObjectCount(data) {
  return axios.post("/quota/cache/getGlobalSearchObjectCount", data)
}
// 保存表格字段展示
export function saveGlobalSearch(data) {
  return axios.post("/globalSearch/fieldShowSetting", data);
}
<template>
  <div class="topContent" ref="topContentRef">
    <div class="topContent_left" ref="topcontentleft">
      <!-- 应用名称，伙伴云关闭展示应用名称 -->
      <div class="topcontentmenu" v-if="!Partnercloud">
        <span class="applicationName">
          {{ applicationName }}
        </span>
      </div>
      <!-- 菜单部分 -->
      <!-- 骨架屏 -->
      <Wang-skeleton type="line" :options="frontOptions" v-if="frontLoading">
      </Wang-skeleton>
      <!-- 菜单内容 -->
      <div class="data_menu" ref="datamenu" v-if="!frontLoading && !showSelectMenu">
        <!-- 展示的菜单部分 -->
        <div
          class="each_menu"
          v-for="(item, index) in showMenus"
          :key="index"
          :ref="`allmenu${index}`"
        >
          <span
            @click.right="clickRight(item, $event)"
            v-if="showMenus.length > 0"
            :class="item.isDefaultLaunchTab === 'true' ? 'cur-menu' : ''"
          >
            <a
              :title="item.tabName"
              @click="clickshowMenu(item, $event)"
              :href="linkHref(item)"
            >
              {{ item.tabName }}
            </a>
          </span>
        </div>

        <!-- 直接显示全部菜单 -->
        <div
          v-if="isShowTabAllMenu && !Partnercloud"
          ref="moreMenus"
          @click="dialogstatus = true"
          class="new_tab_all_menu"
        >
          {{ $t("label.tabpage.alltabs") }}
        </div>
        <!-- 伙伴云直接显示全部菜单 -->
        <div
          v-if="isShowTabAllMenu && Partnercloud"
          ref="moreMenus"
          class="new_tab_all_menu"
        ></div>

        <!-- 更多菜单 -->
        <el-dropdown
          trigger="hover"
          class="moreMenus"
          placement="bottom"
          v-if="!isShowTabAllMenu && setting !== 'setting'"
        >
          <p
            @mouseenter="menuenter"
            class="more-menusbox"
            :class="[menupostion ? 'menupostion' : 'menupostion1']"
            ref="moreMenus"
          >
            <span style="font-size: 14px">{{ $t("label.more") }}</span>
            <i class="el-icon-caret-bottom"></i>
          </p>
          <el-dropdown-menu
            slot="dropdown"
            class="mymore-menus"
            style="
              box-shadow: 0px 0px 8px 1px rgba(45, 108, 252, 0.68);
              white-space: nowrap;
              width: 200px;
              max-height: 310px;
              background: #20233a;
              color: #fff;
              top: 0px;
            "
          >
            <div
              :class="isShowHidden ? 'menus-show-all' : 'menus-show-hidden'"
              class="more-menus-fixed"
              @mouseenter="menusHidden"
              @mouseleave="menusHidden"
            >
              <el-dropdown-item
                :title="item.tabName"
                v-for="(item, index) in moreMenus"
                :key="index"
                @click.native="clickmoreMenu(item,false,$event)"
                :style="{
                  fontWeight: item.id == 'allmenu' ? 'bold' : 'normal',
                  display: 'flex',
                  fontSize: '14px',
                  background: '#20233A',
                  color: '#FFF',
                  padding: '0 4px',
                }"
              >
                <a
                  @click.right="clickRight(item, $event)"
                  v-if="item.id != 'allmenu'"
                  class="link-dropdown-style"
                  :href="linkHref(item)"
                  style="minWidth:'140px',
                    overflow:'hidden',color:'FFF'"
                >
                  <span style="margin-left: 16px">{{ item.tabName }}</span></a
                >
              </el-dropdown-item>
            </div>
            <div class="all-Menu-Btn" @click="clickmoreMenu({}, true,$event)">
              <span>{{ $t("label.tabpage.alltabs") }}</span>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="globalSearch-box" v-show="showSelectMenu">
        <globalSearch class="position" />
      </div>
    </div>
    <!-- 全部菜单 dialog -->
    <div>
      <el-dialog
        :visible.sync="dialogstatus"
        width="930px"
        :closeOnClickModal="false"
        @closed="closedialog"
      >
        <div style="height: 100%; background: #fff">
          <div class="allmenutop">
            <span style="font-size: 14px">{{
              $t("label.tabpage.alltabs")
            }}</span>
            <div class="seachbox">
              <el-input
                :placeholder="$t('report_label_pleaseinput')"
                v-model="seachinput"
                @input="getseachtext"
                @keyup.enter.native="getallmenulist"
                style="display: flex; fontsize: 12px"
              >
              </el-input>
              <div class="seachicon" @click="getallmenulist">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-search"></use>
                </svg>
              </div>
              <div class="letterseach">
                <ul>
                  <li
                    v-for="(letter, index) in letteroption"
                    :key="index"
                    @click="seachletter(letter.name)"
                  >
                    {{ letter.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div></div>
          </div>
          <div class="allmenulist" v-loading="seachloading">
            <ul class="link-text-color-style">
              <li v-for="item in alllication" :key="item.id">
                <a
                @click.right="clickRight(item, $event)"
                  @click.stop="allmenufun(item, $event)"
                  :title="item.tabName"
                  :href="linkHref(item)"
                  >{{ item.tabName }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 自定义菜单显示内容 -->
    <div class="editmenudialog">
      <el-dialog
        :title="$t('label.changemydisplay.list.custom')"
        :visible.sync="editmymenustatus"
        :before-close="handleClose"
        width="550px"
        ><span>
          <div class="editmymenubody" v-loading="editmenuloading">
            <div>
              <!-- 可选菜单 -->
              <span>{{ $t("label_partner_workspace_tab_option") }}</span>
              <div class="Optionalmenu">
                <!-- 菜单搜索框 -->
                <div class="Optionalmenuseach">
                  <input
                    type="text"
                    class="searchBoxInput"
                    v-model="seachvalue"
                    @input="isempty"
                    @keyup.enter="Optionalmenuseach"
                  />
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="margin-bottom: 2px"
                    @click="Optionalmenuseach"
                  >
                    <use href="#icon-search"></use>
                  </svg>
                </div>
                <ul>
                  <li
                    v-for="unselectedTab in seachunselectedTabList"
                    :key="unselectedTab.id"
                    :style="{
                      color: unselectedTab.isshow ? '#006DCC' : '#333333',
                    }"
                    @mouseenter="unselectedTabenter(unselectedTab.id)"
                    @mouseleave="unselectedTableave(unselectedTab.id)"
                  >
                    <span
                      :title="unselectedTab.tabName"
                      class="selectedTab-text"
                      >{{ unselectedTab.tabName }}</span
                    >
                    <i
                      class="el-icon-plus"
                      v-show="unselectedTab.isshow"
                      @click="addselect(unselectedTab.id)"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <span>{{
                $t("label_partner_workspace_tab_selectedoption")
              }}</span>
              <div class="Selectedmenu">
                <ul>
                  <vuedraggable v-model="selectedTabList" @start="drstart">
                    <transition-group>
                      <li
                        v-for="selectedTab in selectedTabList"
                        :key="selectedTab.id"
                        :style="{
                          color: selectedTab.isshow ? '#006DCC' : '#333333',
                        }"
                        @mouseenter="selectedTabTabenter(selectedTab.id)"
                        @mouseleave="selectedTabTableave(selectedTab.id)"
                      >
                        <span
                          :title="selectedTab.tabName"
                          class="selectedTab-text"
                          >{{ selectedTab.tabName }}</span
                        >
                        <i
                          class="el-icon-minus"
                          v-show="selectedTab.isshow && selectedTab.id !== defaultMenu.id"
                          @click="removeselect(selectedTab.id)"
                        ></i>
                      </li>
                    </transition-group>
                  </vuedraggable>
                </ul>
              </div>
            </div>
          </div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">{{
            $t("label_tabpage_cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="savemymenusetting"
            :loading="saveloading"
            >{{ $t("label.ems.confirm") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/**
 * menu组件
 */
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import * as LayoutApi from "./api.js";
import vuedraggable from "vuedraggable";
import VueCookies from "vue-cookies";
import debounce from "lodash.debounce";
import pinyinMatch from "pinyin-match";
import waterMark from "@/utils/waterMark/callMark.js";
import { getSetupInfo } from "./api";
import * as request from "./api.js";
import globalSearch from "@/views/GlobalSearch/newGlobalSearch.vue";

export default {
  props: {
    // 菜单栏上显示的菜单项
    showMenus: {
      type: Array,
      default: () => [],
    },
    // 更多菜单
    moreMenus: {
      type: Array,
      default: () => [],
    },
    menupostion: {
      type: Boolean,
    },
    // 应用程序名称
    applicationName: {
      type: String,
      default: "",
    },
    // 是否在可视范围内展示全部菜单按钮
    isShowTabAllMenu: {
      type: Boolean,
      default: true,
    },
    setting: {
      //是否是设置页面
      type: String,
      default: "",
    },
    changeLoad: {
      type: Boolean,
      default: false,
    },
    topTitleWidth: {
      type: Number,
      default: 0,
    },
    // 所有菜单
    allMenus: {
      type: Array,
      default: () => [],
    },
  },
  //限制菜单选项中最多展示8个字符
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 8) {
        return value.slice(0, 8) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      // 菜单右键打开，将id携带到路由中
      clickRightMenuId:'',
      frontLoading: false,
      // 显示搜索按钮输入框
      showSelectMenu:false,
      clickRightObj: {},
      frontOptions: {
        row: 1,
        column: 8,
        width: "60px",
        height: "14px",
        backgroundColor: "#3C465C",
      },
      isShowHidden: false, //更多菜单是否显示滚动条
      isCcchatShow: "true",
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      showMoreMenu: false,
      seachinput: "",
      seachloading: true,
      editmymenustatus: false,
      dialogstatus: false,
      offsetWidth: 0,
      saveloading: false,
      editmenuloading: false,
      oncestatus: true,
      Optionalmenuinput: "",
      seachvalue: "",
      userurl: "",
      menulist: [],
      alllication: [],
      copyalllication: [],
      selectedTabList: [],
      unselectedTabList: [],
      seachunselectedTabList: [],
      filterunselectedTabList: [],
      letteroption: [
        {
          name: "A",
          value: "A",
        },
        {
          name: "B",
          value: "B",
        },
        {
          name: "C",
          value: "C",
        },
        {
          name: "D",
          value: "D",
        },
        {
          name: "E",
          value: "E",
        },
        {
          name: "F",
          value: "F",
        },
        {
          name: "G",
          value: "G",
        },
        {
          name: "H",
          value: "H",
        },
        {
          name: "I",
          value: "I",
        },
        {
          name: "J",
          value: "J",
        },
        {
          name: "K",
          value: "K",
        },
        {
          name: "L",
          value: "L",
        },
        {
          name: "M",
          value: "M",
        },
        {
          name: "N",
          value: "N",
        },
        {
          name: "O",
          value: "O",
        },
        {
          name: "P",
          value: "P",
        },
        {
          name: "Q",
          value: "Q",
        },
        {
          name: "R",
          value: "R",
        },
        {
          name: "S",
          value: "S",
        },
        {
          name: "T",
          value: "T",
        },
        {
          name: "U",
          value: "U",
        },
        {
          name: "V",
          value: "V",
        },
        {
          name: "W",
          value: "W",
        },
        {
          name: "X",
          value: "X",
        },
        {
          name: "Y",
          value: "Y",
        },
        {
          name: "Z",
          value: "Z",
        },
        {
          name: "ALL",
          value: "ALL",
        },
      ],
      tabIds: [
        //日历
        "acf20200615event",
        //日历管理
        "acf20208AD53CC8sLvgf",
        //同事圈
        "acf201200141050RgtYi",
        //邮件
        "acf20191021FeiAUjdys",
        //销售漏斗
        "acf2015030630xsld",
        //文件
        "acf20171206fileUjdys",
        //价格手册
        "acf2020110PriceBooks",
        //潜在客户公海池
        "acf202011leadmksea",
        //客户公海池
        "acf202011accmksea",
        //仪表板
        "acf100009",
        //短信
        "acf20201216smslist",
        //标情发现
        "acf2020340C93FD7chBP",
        //小组
        "acf20190807sgeAUjdys",
        //派工平台
        "acfFieldServiceSystb",
        //预测
        "acf2013121431foreca",
        //人员资源日历
        "acf20219D0E7E25g4uUv",
        //市场活动表单
        "acf2021070mcforms",
        //社交媒体
        "acf2021070mcsocial",
        //伙伴云-订货平台
        "acf20210713orderpro1",
        //聊天
        "acfConversationTab00",
        // 控制台
        "acfServiceControlTab",
      ],
      renum: 1,
      // 应用程序下的默认菜单
      defaultMenu: {},
    };
  },
  components: {
    vuedraggable,
    globalSearch
  },
  //获取浏览器窗口当前宽度
  updated() {
    this.$nextTick(() => {
      this.computedshowmenu();
    });
  },
  created() {
    // 如果toAPP不为空，则跳转到toAPP，给实施提供的全局跳转app的逻辑
    let toAPP = this.$route.query.toAPP;
    if (toAPP) {
      this.$emit("tabapplication", toAPP);
    }

    //计算字符个数如果是英文或符号则算单字符如果是中文则算双字符-在字符串原型上扩展方法
    String.prototype.byteLength = function () {
      var length = 0;
      Array.from(this).map(function (char) {
        if (char.charCodeAt(0) > 255) {
          //字符编码大于255，说明是双字节字符
          length += 2;
        } else {
          length++;
        }
      });

      return length;
    };

    //根据字符长度计算出dom渲染到页面上时的具体宽度px-在字符串原型上扩展方法
    /*** 获取文本px宽度* @param font{String}: 字体样式**/
    String.prototype.pxWidth = function (font) {
      // re-use canvas object for better performance
      let canvas =
          String.prototype.pxWidth.canvas ||
          (String.prototype.pxWidth.canvas = document.createElement("canvas")),
        context = canvas.getContext("2d");

      font && (context.font = font);
      var metrics = context.measureText(this);

      return metrics.width;
    };
  },
  mounted() {
    // 关闭加载框
    document.getElementById("lightning-loading").style.display = "none";

    // 添加水印
    let openWater = localStorage.getItem("openWater");
    if (openWater == "true") {
      let domApp = document.getElementById("app");
      if (domApp) {
        waterMark(
          domApp,
          document.documentElement.clientWidth + "px",
          document.documentElement.clientHeight + "px",
          "19998",
          "default"
        );
      }
    }

    this.offsetWidth = document.body.offsetWidth;
    let that=this
    window.addEventListener('resize',()=>{
      that.offsetWidth = document.body.offsetWidth;
    })

    this.userurl = this.$cookies.get("domainName");
    this.getallmenulist();
    // 监听应用程序的切换操作，执行切换程序方法
    this.$bus.$on("changeApplication", this.ebFn1);
    //其他页面跳转选项改变样式
    this.$Bus.$on("change-style", this.ebFn2);
    //监听点击搜索菜单
    this.$Bus.$on("enterSelectBtn", this.ebFn3);
    //监听关闭搜索菜单
    this.$Bus.$on("closeSelectBtn", this.ebFn4);
    //监听编辑菜单按钮
    this.$Bus.$on("sendEditMenus",this.ebFn5);

    //如果加载详情页,匹配展示菜单中是否有其父对象选项卡，有则添加选中样式，没有则将详情页所属对象菜单添加到临时菜单中
    this.$Bus.$on("change-detail-style",this.ebFn6);
  },
  destroyed() {
    this.$bus.$on("changeApplication", this.ebFn1);
    this.$Bus.$on("change-style", this.ebFn2);
    this.$Bus.$on("enterSelectBtn", this.ebFn3);
    this.$Bus.$on("closeSelectBtn", this.ebFn4);
    this.$Bus.$on("sendEditMenus",this.ebFn5);
    this.$Bus.$on("change-detail-style",this.ebFn6);
  },
  watch: {
    offsetWidth() {
      this.computedshowmenu();
    },
    // todo:需要处理clickRightObj的问题
    showMenus(newVal) {
      if (newVal.length !== 0) {
        // this.frontLoading = true;
        // let objItem = JSON.parse(localStorage.getItem("clickRightObj"));
        // if (objItem) {
        //   newVal.forEach((item) => {
        //     item.isDefaultLaunchTab = "false";
        //     if (item.id == objItem.id) {
        //       item.isDefaultLaunchTab = "true";
        //     }
        //   });
        // }
        this.frontLoading = false;
        this.$emit("setfrontLoading", false);
      }
    },

    changeLoad(newVal) {
      if (newVal && newVal == true) {
        this.frontLoading = true;
      }
    },
    $route(val) {
      if (val.path) {
        // 关闭加载框
        document.getElementById("lightning-loading").style.display = "none";
      }
    },
  },
  methods: {
        /**
     * 需要同时在sessionStorage和localStorage中存储applicationTab，防止后期出现左键新窗口或者新标签页打开的情况
     * @param {String} tabId:菜单项id
     */
    setApplicationTab(tabId=''){
      if(!tabId)return
      localStorage.setItem('applicationTab',tabId)
      sessionStorage.setItem('applicationTab',tabId)
    },
    /**
     * 优先使用sessionStorage中的数据
     */
    getApplicationTab(){
      return sessionStorage.getItem('applicationTab')?sessionStorage.getItem('applicationTab'):localStorage.getItem('applicationTab')
    },
    ebFn1(e){
      if (e.type && e.type === "pool") {
        // 选择的公海池跳转对应页面
        this.allmenufun(e);
      }
      this.clearotherpage(e.id);
    },
    ebFn2(params){
      this.clearotherpage(params);
    },
    ebFn3(){
      this.showSelectMenu=true;
    },
    ebFn4(){
      this.showSelectMenu=false;
    },
    ebFn5(){
      this.openeditmymenu();
    },
    ebFn6 (params){
      // 判断菜单栏上显示的菜单项中是否存在
      let isChange = this.showMenus.filter((item) => {
        return params == item.objId;
      });
      // 判断更多菜单中是否存在
      let isMore = this.moreMenus.filter((item) => {
        return params == item.objId;
      });
      // 在所有菜单中查找
      let isAll = this.alllication.filter((item) => {
        return params == item.objId;
      });
      // 展示菜单中存在，直接加选中标识
      if (isChange.length != 0) {
        this.clearotherpage(isChange[0].id);
        isChange[0].isDefaultLaunchTab = "true";
        sessionStorage.setItem("applicationTab", isChange[0].id);
      } else if (isMore.length != 0) {
        // 更多菜单中存在，删除更多菜单中数据，添加到展示菜单中（更多菜单去重在父组件changeshowmenu方法中执行）
        this.clearotherpage(isMore[0].id);
        isMore[0].isDefaultLaunchTab = "true";
        // 将当前菜单从更多菜单放入到展示菜单中,并且删除更多菜单中该项（此时不能保证顺序）
        setTimeout(() => {
          this.$emit("changeshowmenu", isMore[0], true);
        }, 300);
        sessionStorage.setItem("applicationTab", isMore[0].id);
      } else if (isAll.length != 0) {
        // 全部菜单中存在，将该菜单作为临时菜单展示(更多菜单去重)
        this.clearotherpage(isAll[0].id);
        isAll[0].isDefaultLaunchTab = "true";
        setTimeout(() => {
          this.$emit("changeshowmenu", isAll[0], true);
        }, 300);
        sessionStorage.setItem("applicationTab", isAll[0].id);
      } else {
        // 清空展示、更多菜单中的选中状态
        this.clearshowMenu();
        this.clearmoreMenu();
      }
    },
    /**
     * 关闭编辑菜单弹框触发方法
     */
    handleClose() {
      this.seachvalue = "";
      this.editmymenustatus = false;
    },
    /**
     * 菜单项右键打开，此时记录切换菜单的id，路由跳转时携带该菜单id
     */
    clickRight(item) {
      if(item.id){
        this.clickRightMenuId=item.id
        localStorage.setItem("clickRightObj", JSON.stringify(item));
      }
      
    },
    // 鼠标进入或者离开更多菜单
    menusHidden() {
      this.isShowHidden = !this.isShowHidden;
    },
    // 仪表板跳转前调用仪表板最近查看接口，如果有数据跳转到仪表板页面，无数据跳转到仪表板文件夹列表页
    async getDashboardList() {
      let params = {
        name: "", //搜索内容
        dashboardfolderid: "recentDashboard",
        islightning: "true",
        page: "1",
        pageSize: "10000",
      };
      let data = await request.getDashboardList(params);
      if (data.data.listDashboard && data.data.listDashboard.length > 0) {
        let id = data.data.listDashboard[0].id;
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${id}`,
          query: {},
        });
      } else {
        this.$router.push({
          path: `/dashboardObject`,
        });
      }
    },

    /**
     * 其他页面切换选项卡,添加菜单选中样式
     * @param {String} params：菜单id
     */
    clearotherpage(params) {
      this.clearshowMenu();
      this.clearmoreMenu();
      this.showMenus.forEach((val) => {
        if (params == val.id) {
          val.isDefaultLaunchTab = "true";
        }
      });
    },
    menuenter() {
      this.showMoreMenu = true;
    },
    menuleave() {
      this.showMoreMenu = false;
    },
    openeditmymenu() {
      this.getmymenusetting();
      this.editmymenustatus = true;
    },
    //关闭临时菜单
    closetemporary() {
      this.$emit("canceltemporary");
    },
    //关闭全部菜单弹框时重新赋值所有数据
    closedialog() {
      this.seachinput = "";
      this.getallmenulist();
    },
    //可选菜单中移入样式
    unselectedTabenter(id) {
      this.unselectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = true;
        }
      });
    },
    //可选菜单中移出样式
    unselectedTableave(id) {
      this.unselectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = false;
        }
      });
    },
    //已选菜单中移入样式
    selectedTabTabenter(id) {
      this.selectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = true;
        }
      });
    },
    //已选菜单中移出样式
    selectedTabTableave(id) {
      this.selectedTabList.forEach((item) => {
        if (item.id == id) {
          item.isshow = false;
        }
      });
    },
    //可选菜单向已选菜单中添加选项
    addselect(id) {
      let addselectval;
      this.seachunselectedTabList.forEach((item, index) => {
        if (item.id == id) {
          item.isshow = false;
          addselectval = this.seachunselectedTabList.splice(index, 1);
        }
      });
      this.selectedTabList.push(addselectval[0]);
      this.filterunselectedTabList.push(addselectval[0]);
    },
    //已选菜单向可选菜单中添加选项
    removeselect(id) {
      if (this.selectedTabList.length === 1) {
        this.$message.warning(this.$i18n.t("label.partnerCloud.selectOne"));
        return false;
      }
      let removeselectval;
      this.selectedTabList.forEach((item, index) => {
        if (item.id == id) {
          item.isshow = false;
          removeselectval = this.selectedTabList.splice(index, 1);
        }
      });
      this.unselectedTabList.unshift(removeselectval[0]);
      this.Optionalmenuseach();
    },
    //拖拽选项时清除样式
    drstart() {
      this.selectedTabList.forEach((item) => {
        item.isshow = false;
      });
    },
    //可选菜单中搜索
    Optionalmenuseach() {
      if (this.seachvalue == "") {
        this.seachunselectedTabList = this.unselectedTabList;
      } else {
        this.seachunselectedTabList = this.unselectedTabList.filter((item) => {
          // 搜索不需要区分大小写
          let tabName = item.tabName.toUpperCase();
          let seachvalue = this.seachvalue.toUpperCase();
          return tabName.includes(seachvalue);
        });
      }
    },
    //监听可选菜单中搜索框是否为空
    isempty() {
      if (this.seachvalue == "") {
        this.unselectedTabList.forEach((item, index) => {
          this.filterunselectedTabList.forEach((value) => {
            if (item.id == value.id) {
              this.unselectedTabList.splice(index, 1);
            }
          });
        });
        this.seachunselectedTabList = this.unselectedTabList;
      }
    },
    //提交编辑后的自定义菜单
    savemymenusetting() {
      this.saveloading = true;
      let filterids = [];
      this.selectedTabList.forEach((item) => {
        filterids.push(item.id);
      });
      let filterIdsStr = filterids.join(",");
      LayoutApi.savemymenusetting({
        id: this.$store.state.home.homeApplicationId,
        tabIds: filterIdsStr,
      })
        .then((res) => {
          this.saveloading = false;
          this.editmymenustatus = false;
          this.seachvalue = "";
          if (res.result == true) {
            this.$emit(
              "Refreshapplicationview",
              this.$store.state.home.homeApplicationId
            );
            this.getmymenusetting();
          }
        })
        .catch(() => {});
    },
    /**
     * getmymenusetting:获取可选菜单及已选菜单
     *
     */
    getmymenusetting() {
      this.editmenuloading = true;
      LayoutApi.getmymenusetting({
        id: this.$store.state.home.homeApplicationId,
      })
        .then((res) => {
          let filterselectedTabList = [];
          res.data.selectedTabList &&
            res.data.selectedTabList.forEach((value) => {
              if (this.tabIds.indexOf(value.id) !== -1) {
                value.type = "";
              } else if (value.id == "acf20200615task") {
                value.type = "object";
                value.objectApi = "Task";
                value.objId = "task";
                value.prefix = "bfa";
              }
              value.isshow = false;
              filterselectedTabList.push(value);
            });
          this.selectedTabList = filterselectedTabList.filter((item) => {
            if (this.isCcchatShow === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          let filterunselectedTabList = [];
          res.data.unselectedTabList &&
            res.data.unselectedTabList.forEach((value) => {
              if (this.tabIds.indexOf(value.id) !== -1) {
                value.type = "";
              } else if (value.id == "acf20200615task") {
                value.type = "object";
                value.objectApi = "Task";
                value.objId = "task";
                value.prefix = "bfa";
              }
              value.isshow = false;
              filterunselectedTabList.push(value);
            });
          this.unselectedTabList = filterunselectedTabList.filter((item) => {
            if (this.isCcchatShow === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          LayoutApi.getApplicationTab({
            id: this.$store.state.home.homeApplicationId,
          }).then(response =>{
            if (response.result) {
              this.defaultMenu = {}
              this.defaultMenu = response.data?.find(item => {
                return item.isDefaultLaunchTab === "true"
              })
            }
          })
          this.editmenuloading = false;
          this.Optionalmenuseach();
          this.$emit("cancelLoading");
        })
        .catch(() => {});
    },
    /**
     * 判断是否有空间添加一个菜单项,间隔是70,父组件调用了
     * @param {String} tagMenu:需要添加的菜单项
     */
    isAdd(tagMenu={}){
      if(!tagMenu.id||!tagMenu.tabName)return false;

      // body宽度减去菜单宽度（菜单右侧到浏览器窗口右边框的距离）
      if(!this.$refs.moreMenus)return;
        let moremenuright =
          this.offsetWidth - this.$refs.moreMenus.getBoundingClientRect().right;
        // this.topTitleWidth:编辑菜单按钮的宽度(顶部右侧部分)（顶部分为左侧图标、顶部菜单、右侧部分）
        // 替换菜单项长度
        let tagMenuLen=parseInt(tagMenu.tabName.pxWidth("normal 14px Robot"))
        let  len= moremenuright-this.topTitleWidth;
        return tagMenuLen+70<len
    },
    /**
     * 计算当前浏览器窗口大小所展示的菜单数量
     */
    computedshowmenu: debounce(function () {
      // 如果有更多菜单、全部菜单
      if (this.$refs.moreMenus) {
         // 最后一个菜单的宽度
        let lastoffwidth = 0;
       
          lastoffwidth =
            this.showMenus.length > 0
            ? parseInt(this.showMenus[this.showMenus.length - 1].tabName.pxWidth("normal 14px Robot"))
            : 0;
        // 同一个菜单通过pxWidth和offsetWidth获取的数值差8或者9，pxWidth获取的值小，这里全部使用pxWidth
        // 根据字符计算更多菜单中第一个菜单宽度
        let ListFirstWidth =
          this.moreMenus.length > 0
            ? parseInt(this.moreMenus[0].tabName.pxWidth("normal 14px Robot"))
            : 0;
          // body宽度减去菜单宽度（菜单右侧到浏览器窗口右边框的距离）
          
        let moremenuright =
          this.offsetWidth - this.$refs.moreMenus.getBoundingClientRect().right;

        // 更多按钮的宽度
        // let moremenuswidth = this.$refs.moreMenus.offsetWidth;
        // 编辑菜单按钮的宽度(顶部右侧部分)（顶部分为左侧图标、顶部菜单、右侧部分）
        let editmenuwidth = this.topTitleWidth;
       
            // a:菜单到右侧编辑菜单按钮这部分距离，这部分距离为定值，设为70，不能太小，更多菜单标签切换为全部菜单时会换行，判断展示菜单中最后一项的宽度或者更多菜单中第一项的宽度，决定是否增加或者删除（注意下通过pxWidth计算的数值与实际渲染的数值大小误差8或者9）
            // 产生菜单闪烁的原因：
            // 1、当距离足够增加一个更多中的菜单时，执行addsurplus，此时更新，触发updated，再次调用，因为展示菜单数组最后一项改变了，此时距离满足窗口缩小判断，又调用了deletesurplus，如此产生了菜单闪烁问题
            // 窗口增加了10px的空白区，调用deletesurplus和addsurplus有10px的空白区，防止闪烁
            // pxWidth，实际渲染还有25px的margin-right的值
            // 菜单最大长度为173
            lastoffwidth=lastoffwidth>173?173:lastoffwidth
            ListFirstWidth=ListFirstWidth>173?173:ListFirstWidth
            
        let a = moremenuright - editmenuwidth;
        // 窗口缩小
        if ((a - lastoffwidth <= 80)&&!(a+lastoffwidth+25 > lastoffwidth + 70)) {
          this.$emit("deletesurplus");
        }
        // 窗口变大
        if ((a > ListFirstWidth + 70)&&!(a - ListFirstWidth-25 <= ListFirstWidth+80)) {
          // 
          this.$emit("addsurplus");
        }
        
      }
    }, 0),

    //按照英文字母搜索选项关键字
    seachletter(val) {
      // const pinyinMatch = require("pinyin-match");
      if (val && val != "ALL") {
        let result = [];
        this.copyalllication.forEach((e) => {
          var m = pinyinMatch.match(e.tabName.charAt(0), val);
          if (m) {
            result.push(e);
          }
        });
        this.alllication = result;
      }

      if (val == "ALL") {
        this.alllication = this.copyalllication;
      }
    },
    //监听全部菜单中搜索框是否为空
    getseachtext() {
      if (this.seachinput == "") {
        this.alllication.length = 0;
        if (this.alllication.length == 0) {
          this.getallmenulist();
        }
      }
    },
    // 获取CCChat后台设置信息
    async getSetupInfo() {
      let res = await getSetupInfo();
      if (res.result) {
        this.isCcchatShow = res.data.isEnable;
      }
    },
    //获取全部菜单
    getallmenulist() {
      this.seachloading = true;
      let filtercalendar = [];
      LayoutApi.seachApplicationTab({
        searchKey: this.seachinput,
      })
        .then((res) => {
          this.seachloading = false;
          res.data.forEach((value) => {
            if (this.tabIds.indexOf(value.id) !== -1) {
              value.type = "";
              //任务
            } else if (
              value.id == "acfTargetmodelstdtab" ||
              value.id == "acfTargetstdtab00" ||
              value.id == "acf2013121431foreca"
            ) {
              value.type = "";
            } else if (value.id == "acf20200615task") {
              value.type = "object";
              value.objectApi = "Task";
              value.objId = "task";
              value.prefix = "bfa";
            }
            filtercalendar.push(value);
          });
          this.alllication = filtercalendar.filter((item) => {
            if (this.isCcchatShow === "true") {
              return item.type != "applauncher" && item.id != "acf000010";
            } else {
              return (
                item.type != "applauncher" &&
                item.id != "acf000010" &&
                item.id != "acf201200141050RgtYi" &&
                item.id != "acf201300143141ebsD8"
              );
            }
          });
          this.copyalllication = this.alllication;
        })
        .catch(() => {});
    },
    // 点击切换展示菜单（顶部横向菜单，左侧应用程序不会执行）
    clickshowMenu(item, e) {
      e.preventDefault();
      // 清空菜单右键打开存储的id
      this.clickRightMenuId=''
      if(item.type==='page'||item.type==="web"){
        // 执行点击切换菜单逻辑
      this.$emit("clickshowMenu", item);
      this.$bus.$emit("hideTool", false); //改变列表视图设置字段弹框为false
      }else{
      
      let tableListConfig = {
        prefix: "", // 视图id
        viewId: "", // 视图id
        pageSize: 25, // 每页条数
        skippageNum: 1, // 当前页数
        applicationId: item.id, // 应用id
        sortField: "", // 当前排序字段
        sortDir: "", // 当前排序顺序
      };
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件
      
      this.$store.state.List = item;
      VueCookies.set("activeTabObjId", item.objId || item.id, { sameSite: 'Strict' }); //cookie存储菜单id用来特殊处理(mjx)
      // 清空选中样式
      this.showMenus.forEach(item=>{item.isDefaultLaunchTab = "false";})
      // 设置选中样式
      // item.isDefaultLaunchTab = "true";
      // 执行点击切换菜单逻辑
      this.$emit("clickshowMenu", item);
      this.$bus.$emit("hideTool", false); //改变列表视图设置字段弹框为false
      }
    },
    //点击切换更多菜单
    clickmoreMenu(item, allBool,e) {
      e.preventDefault();
      // 清空菜单右键打开存储的id
      this.clickRightMenuId=''
      if(item.type==='page'||item.type==="web"){
        // 执行点击切换菜单逻辑
      this.$emit("clickshowMenu", item);
      
      }else{
      let tableListConfig = {
        prefix: "", // 视图id
        viewId: "", // 视图id
        pageSize: 25, // 每页条数
        skippageNum: 1, // 当前页数
        applicationId: item.id, // 应用id
        sortField: "", // 当前排序字段
        sortDir: "", // 当前排序顺序
      };
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件

      if (allBool) {
        let allmenuItem = {};
        this.moreMenus.map((item) => {
          if (item.id == "allmenu") {
            allmenuItem = item;
          }
        });
        this.$store.state.List = allmenuItem;
        this.$emit("clickmoreMenu", allmenuItem,true);
        this.getSetupInfo();
        this.getallmenulist();
      }
      // 点击其余菜单
      else {
        
        this.$store.state.List = item;
        this.$emit("clickmoreMenu", item,true);
      }
      }
    },
    //清除展示菜单选中状态
    clearshowMenu() {
      this.showMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
    //清除更多菜单选中状态
    clearmoreMenu() {
      this.moreMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
    showallmenu() {
      this.dialogstatus = true;
    },
    //点全部菜单中击切换页面并执行临时菜单方法
    allmenufun(tag, e) {
      e.preventDefault();
      // 清空菜单右键打开存储的id
      this.clickRightMenuId=''
      if(tag.type==='page'||tag.type==="web"){
        // 执行点击切换菜单逻辑
      this.$emit("clickshowMenu", tag);
      
      }else{
      let tableListConfig = {
        prefix: "", // 视图id
        viewId: "", // 视图id
        pageSize: 25, // 每页条数
        skippageNum: 1, // 当前页数
        applicationId: tag.id, // 应用id
        sortField: "", // 当前排序字段
        sortDir: "", // 当前排序顺序
      };
      this.$store.commit("setTableListConfig", tableListConfig); // 存储列表条件
      this.setApplicationTab(tag.id)
      VueCookies.set("activeTabObjId", tag.objId || tag.id, { sameSite: 'Strict' }); //cookie存储菜单id用来特殊处理(mjx)
      this.$emit("clickmoreMenu", tag);
      }
      // 关闭全部菜单弹窗
      this.dialogstatus=false
    },
  },
  computed: {
    hrefInfo() {
      return (data) => {
        // 此data包含当前使用对象
        if (!data.type) {
          return "我的type为空，不知道跳到那里去";
        } else {
          return `#/commonObjects/views/table/${data.objectApi}/${data.objId}/${data.prefix}`;
        }
      };
    },
    // 菜单的a标签跳转（左击直接跳转，右击才会执行该函数返回的路由地址）
    linkHref() {
      return (item) => {
        
        // 判断是否通用对象 1 表示不是自定义对象
        if (item.id == "p1020207266DDC2nZ3Jp") {
          return `/#/actualworklist/listviews?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf2021070mcforms") {
          //市场活动表单
          return `/#/marketForm/marketForm?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf2021070mcsocial") {
          //市场云社交媒体
          return `/#/social/social?CRId=${this.clickRightMenuId}`;
        } else if (item.id == "acfganttchart001") {
          return `/#/gantt/gantt?CRId=${this.clickRightMenuId}`;
        } else if (item.id == "acf20207266DDCLKAIQ1") {
          return `/#/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02?CRId=${this.clickRightMenuId}`;
        }
        else if (item.id === "acfShiftstdtab00") {
          return `/#/commonObjects/views/plan/${item.objectApi}/${item.objId}/${item.prefix}?CRId=${this.clickRightMenuId}`;
        } else if (item.type === "object") {
          return `/#/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}?CRId=${this.clickRightMenuId}`;
        } else if (item.type === "web") {
          return item.url+`?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf100000") {
          return `/#/reportObject/reportList?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf000001") {
          let returnPath = `/#/homePageObject?CRId=${this.clickRightMenuId}`;
          return returnPath;
        }
        else if (item.id === "acf100009") {
          return `/#/dashboardObject?CRId=${this.clickRightMenuId}`;
        } else if (
          item.id === "acf20200615event" ||
          item.id === "acf20208AD53CC8sLvgf"
        ) {
          return `/#/CalendarHeader?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf20171206fileUjdys") {
          return `/#/ccFileCenter?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf20191021FeiAUjdys") {
          return `/#/relation?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf201200141050RgtYi") {
          return `/#/colleagueCircle?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf2015030630xsld") {
          return `/#/commonObjects/hopper?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf2020110PriceBooks") {
          return `/#/priceManual/price-manual?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acfTargetmodelstdtab") {
          return `/#/targetModel/index?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acfTargetstdtab00") {
          return `/#/target/index?CRId=${this.clickRightMenuId}`;
        } else if (item.id == "acf20201216smslist") {
          return `/#/noteSms/noteSmsList?CRId=${this.clickRightMenuId}`;
        }
        // 公海池部分
        if (item.id === "acf202011leadmksea") {
          // 潜在客户公海池
          return `/#/clientPool/Lead/lead/004?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf2021070mcforms") {
          //市场活动表单
          return `/#/marketForm/marketForm?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf2021070mcsocial") {
          //市场云社交媒体
          return `/#/social/social?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf202011accmksea") {
          // 客户公海池
          return `/#/clientPool/Account/account/001?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acfFieldServiceSystb") {
          //派工平台
          return `/#/dispatchingUnits?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf20190807sgeAUjdys") {
          //小组
          return `/#/groupObject/groupList?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf20219D0E7E25g4uUv") {
          //人员资源日历
          return `/#/resoursecalendar/resoursecalendar?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "acf2013121431foreca") {
          //预测
          return `/#/forecast/forecast`;
        } else if (item.id === "acf20210713orderpro1") {
          //伙伴云-订货平台
          return `/#/ordering?CRId=${this.clickRightMenuId}`;
        } else if (item.id === "systemSettings") {
          // 系统设置页的顶部路由，写死
          return `/#/systemSettings?CRId=${this.clickRightMenuId}`;
          //聊天
        } else if (item.id === "acfConversationTab00") {
          return `/#/customerWorkbench/workbench?CRId=${this.clickRightMenuId}`;
        }
        //控制台
        else if (item.id === "acfServiceControlTab") {
          return `/#/commonObjects/views/table/cloudcccase/201100000005024CxOSz/008?isConsole=true&CRId=${this.clickRightMenuId}`;
        } else if (item.iscustom === "1") {
          // 自定义页面
          if (
            item.lightningpageId &&
            item.lightningpageId.indexOf("#lightning") != -1
          ) {
            // 老自定义页面
            return `#/injectionComponent?page=${
              item.lightningpageId.split("#")[0]
            }&button=Home&CRId=${this.clickRightMenuId}`;
          } else {
            // 老自定义页面
            return `#/custome/customePage?CRId=${this.clickRightMenuId}`;
          }
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.selectedTab-text {
  display: block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a {
  text-decoration: none !important;
}
input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}
h3 {
  margin: 0;
  padding: 0;
  list-style: none;
}
::v-deep .mymore-menus {
  ::-webkit-scrollbar {
    width: 3px; /*对垂直流动条有效*/
    height: 84px; /*对水平流动条有效*/
    background: #20233a;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #abc4fe;
  }
  ::-webkit-scrollbar-track {
    background-color: #20233a;
    border-radius: 9px;
  }
}
::v-deep.el-popper .popper__arrow::after {
  border-width: 0;
}
::v-deep.el-popper .popper__arrow {
  border-width: 0;
}
::v-deep .moreMenus {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  cursor: pointer;
  color: #fff;
  min-width: 70px;
  line-height: 46px;

  .more-menusbox:hover {
    color: #abc4fe !important;
  }
}
::v-deep .el-card__body {
  padding: 0;
  span {
    margin-left: 20px;
  }
}
::v-deep .el-dialog__header {
  padding: 0 !important;
  border: none !important;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
}
.editmenu {
  padding-right: 10px;
  cursor: pointer;
  span {
    font-size: 20px !important;
  }
}
.editicon {
  // position: absolute;
  top: 5px;
  right: 26px;
}
.editmenudialog {
  ::v-deep .el-dialog__header {
    padding: 16px 14px 16px 20px !important;
    border-bottom: 1px solid #dcdcdc !important;
  }
  .editmymenubody {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .Optionalmenu {
    width: 240px;
    height: 400px;
    border: 1px solid #d8d8d8;
    overflow: scroll;
    padding: 0 10px;
    .Optionalmenuseach {
      position: relative;
      margin-left: -3px;
      .searchBoxInput {
        margin-top: 10px;
        width: calc(100% - 17px);
      }
      img {
        position: absolute;
        left: 0;
        bottom: 3px;
        cursor: pointer;
      }
    }
    ul {
      border-top: 1px solid #dcdcdc;
      li {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          cursor: pointer;
        }
      }
    }
  }
  .Selectedmenu {
    width: 240px;
    height: 400px;
    border: 1px solid #d8d8d8;
    overflow: scroll;
    padding: 0 10px;
    li {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        cursor: pointer;
      }
      i {
        cursor: pointer;
      }
    }
  }
}
.seachbox {
  position: relative;
  ::v-deep .el-input__inner {
    padding-right: 29px;
    width: 400px;
    height: 33px;
    margin: 0 auto;
  }
  .seachicon {
    position: absolute;
    right: 35px;
    top: 10px;
    // top: 5px;
    cursor: pointer;
  }
  .letterseach {
    margin-top: 9px;
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 12px;
      li {
        color: #999999;
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
}

.applicationName {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  max-width: 145px;
  overflow:hidden;
  word-break:keep-all;
  white-space:nowrap;
  text-overflow:ellipsis;
  margin-right: 20px;
}

.menulist {
  .application {
    width: 150px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
    }
  }
}
.menupostion {
  margin: 0;
  // position: fixed;
  // top: 65px;
  // right: 42px;
}

.menupostion1 {
  margin: 0;
  // position: fixed;
  // top: 65px;
  // left: 264px;
}
.cardimg {
  margin-right: 5px;
  width: 15px;
  height: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}
.allmenutop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: #f8f8f8;
  .allmenuicon {
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h3 {
    font-weight: bold;
    margin-left: 5px;
  }
}
.allmenulist {
  padding-bottom: 10px;
  ul {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 40px 0 40px 100px;
    li {
      width: 33.3%;
      margin-bottom: 15px;
      span {
        cursor: pointer;
      }
    }
  }
}

.topContent {
  position: relative;
  padding: 0 30px 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .topContent_left {
    display: flex;
    align-items: center;
    .topcontentmenu {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #fff;
      }
    }
    .data_left {
      width: 155px;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #080707;
      img {
        width: 21px;
        height: 21px;
        margin-right: 24px;
      }
    }
    .data_menu {
      user-select: none;
      height: 46px;
      display: flex;
      font-size: 14px;
      padding-top: 2px;
      .each_menu {
        display: flex;
        align-items: center;
        margin-right: 25px;
        span {
          display: flex;
          align-items: center;
          padding: 4px 2px;
          border: 2px #20233a solid;
          a {
            max-width: 140px;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: center;
            color: #fff;
            height: 20px;
          }
          a:hover {
            color: #abc4fe !important;
          }
        }
      }
      ::v-deep .el-popper {
        margin-top: 0;
      }
    }
    .globalSearch-box{
      width:600px;
      height:32px;
    }
  }

  .cur-menu {
    border-bottom: 2px solid #abc4fe !important;
  }

  .topContent_right {
    position: relative;
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: #080707;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      &:hover {
      }
    }
  }

  .edit-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.see-more {
  text-align: center;
  color: #006dcc;
  cursor: pointer;
  font-size: 12px;
}

.link-text-color-style {
  li {
    a {
      color: #000 !important;
      font-size: 14px;
      display: inline-block;
      max-width: 150px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.link-dropdown-style {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  color: #fff !important;
  &:hover {
    color: #abc4fe !important;
  }
}
.link-dropdown-style:hover {
  background: #2e344e;
  border-radius: 3px;
}

.new_tab_all_menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #fff;
  line-height: 46px;
  white-space: nowrap;
}
::v-deep.el-dropdown-menu,
::v-deep.el-popper {
  top: 30px !important;
  border: solid 1px #20233a;
  padding: 5px 0 0 0;
  margin-bottom: 0;
  .all-Menu-Btn {
    cursor: pointer;
    background: #20233a;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px #3a3e5e solid;
    margin-top: 8px;
    color: #6c98fd;
    &:hover {
      color: #0051e0;
    }
  }
}

.menus-show-all {
  overflow: auto;
}
.menus-show-hidden {
  overflow: hidden;
}
.more-menus-fixed {
  max-height: 260px;
}

.all-menus {
  text-align: center;
  border-top: 1px solid #3a3e5e;
  margin-top: 12px;
  a {
    color: #6c98fd !important;
    background: #20233a !important;
    margin-top: 10px;
  }
  .link-dropdown-style:hover {
    color: #2d6cfc !important;
  }
}
</style>

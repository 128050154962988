<template>
  <div>
    <div class="people-list">
      <div class="tips">
        <div class="el-icon-info infoIcon"></div>
        <p>应用于全局搜索中搜索的对象，最多可设置{{ searchCount }}个对象。</p>
      </div>
      <div class="hasList">
        <!-- <div class="title">可选对象</div> -->
        <div class="title">{{ $t("label.unselect.object") }}</div>
        <div style="position: relative">
          <div class="body">
            <div class="input-box">
              <div class="inputTitle">
                <input style="width: 165px" type="text" v-model="input" />
              </div>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-search"></use>
              </svg>
            </div>
            <div class="list" style="padding-top: 30px">
              <div
                class="list-item"
                v-for="(item, index) in filterList"
                :key="item.id"
                @mouseover="mouseOver(index)"
                :style="{
                  background: addCurrentIndex == index ? '#F5F5F5' : '#fff',
                  color: addCurrentIndex == index ? '#006DCC' : '#333333',
                }"
              >
                <div class="name">{{ item.tlabel }}</div>
                <div
                  class="symbol"
                  v-show="addCurrentIndex == index"
                  @click="selectPeo(item, index)"
                >
                  <span>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-tianjiablue"></use>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hasList isSelect">
        <!-- <div class="title">已选对象</div> -->
        <div class="title">{{ $t("label.selected.object") }}</div>
        <div class="body" style="padding-top: 0px">
          <div class="list" style="padding-top: 0px">
            <div
              class="list-item"
              v-for="(item, index) in isSelectPeoList"
              :key="item.id"
              @mouseover="isSelectMouseOver(index)"
              :style="{
                background: deleteCurrentIndex == index ? '#F5F5F5' : '#fff',
                color: deleteCurrentIndex == index ? '#006DCC' : '#333333',
              }"
            >
              <div class="name">{{ item.tlabel }}</div>
              <div
                class="symbol"
                v-show="deleteCurrentIndex == index"
                @click="deletePeo(index)"
              >
                <span class="el-icon-minus"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box"></div>
    <div class="buttom-button">
      <!-- 确定 -->
      <div class="button submit" @click="submitMessage">
        {{ $t("label.confirm") }}
      </div>
      <!-- 取消 -->
      <div class="button" @click="cancelSelect">
        {{ $t("label.ems.cancel") }}
      </div>
    </div>
  </div>
</template>

<script>
import { getObjectSetting, getGlobalSearchObjectCount } from "./api.js";
export default {
  data() {
    return {
      allList: [],
      peopleList: [],
      input: "",
      //显示添加人员按钮
      shouAdd: false,
      //鼠标当前移入的数据下标
      addCurrentIndex: null,
      deleteCurrentIndex: null,
      //显示删除人员按钮
      shouDelete: false,
      isSelectPeoList: [],
      // 最多可设置对象数量
      searchCount: 0,
    };
  },
  computed: {
    filterList() {
      if (!this.input) return this.peopleList;
      return this.peopleList.filter((res) => {
        return new RegExp(this.input, "i").test(res.tlabel);
      });
    },
  },
  mounted() {
    this.getGloObj();
    this.getGlobalSearchObjectCount();
  },
  methods: {
    //获取全局对象
    async getGloObj() {
      let result = await getObjectSetting();
      let data = result.data;
      //将
      //如果首次登录
      for (var key in result.data.selectedObjList) {
        if (data.selectedObjList[key].id == "") {
          data.selectedObjList.splice(key, 1);
        }
      }
      if (result.data.selectedObjList.length == 0) {
        // this.peopleList = data.noSelectedObjList.splice(10);
        this.peopleList = result.data.noSelectedObjList.filter(
          (item) => {
            let rules =
              item.id !== "account" &&
              item.id !== "contact" &&
              item.id !== "opportunity" &&
              item.id !== "lead";
            return rules;
          }
        );
        // this.isSelectPeoList = data.noSelectedObjList.splice(0, 10);
        this.isSelectPeoList = result.data.noSelectedObjList.filter(
          (item) => {
            let rules =
              item.id == "account" ||
              item.id == "contact" ||
              item.id == "opportunity" ||
              item.id == "lead";
            return rules;
          }
        );
      } else {
        this.peopleList = data.noSelectedObjList;
        this.isSelectPeoList = data.selectedObjList;
      }
    },
    //删除当前选中的对象
    deletePeo(index) {
      var result = this.isSelectPeoList.splice(index, 1);
      let num = this.peopleList.findIndex(
        (res) => res.prefix == result[0].prefix
      );
      if (num < 0) {
        this.peopleList.push(result[0]);
      }
    },
    // 最多可设置对象数量限制
    async getGlobalSearchObjectCount() {
      let result = await getGlobalSearchObjectCount();
      if(result.result === true) {
        this.searchCount = result.data;
      } else {
        this.searchCount = 10;
      }
    },
    //选择搜索对象
    selectPeo(item) {
      if (this.isSelectPeoList.length >= this.searchCount) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `最多可设置${this.searchCount}个对象`,
        });
        return false;
      }
      this.peopleList.map((Sitem, Sindex) => {
        if (Sitem.id == item.id) {
          this.peopleList.splice(Sindex, 1);
        }
      });
      let index = this.isSelectPeoList.findIndex((res) => res.id == item.id);
      if (index < 0) {
        this.isSelectPeoList.push(item);
      }
    },
    //鼠标移入
    isSelectMouseOver(index) {
      this.deleteCurrentIndex = index;
    },
    //鼠标移入
    mouseOver(index) {
      this.addCurrentIndex = index;
    },
    //取消选择
    cancelSelect() {
      this.$emit("changeSearch", false);
      this.getGloObj();
    },
    //确定选中的全局对象
    submitMessage() {
      if (this.isSelectPeoList.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请至少选择一个对象",
        });
        return false;
      }
      this.$emit("deliverName", this.isSelectPeoList);
      this.$emit("changeSearch", false);
    },
  },
};
</script>

<style lang='scss' scoped>
.tips {
  display: flex;
}
.inputTitle {
  width: 187px;
  position: absolute;
  left: 2px;
  top: 1px;
}
.infoIcon {
  margin: 5px;
}

  .people-list {
    width: 100%;
    padding: 12px;
    padding-top: 0;
    box-sizing: border-box;
    overflow: hidden;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    .hasList {
      float: left;
      width: 200px;
      height: 290px;
      color: #333;
      font-size: 12px;
      .title {
        margin-bottom: 5px;
        font-weight: 500;
      }

      .body {
        border: 1px solid #d8d8d8;
        overflow: auto;
        width: 100%;
        height: 270px;
        border-radius: 3px;
        .list {
          overflow: hidden;
          .list-item {
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              // width: 140px;
              // width: 100%;
              max-width: 150px;
              float: left;
              padding-right: 5px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin: 0;
              padding: 0;
            }
            .symbol {
              float: right;
              margin-right: 15px;
              line-height: 30px;
              cursor: pointer;
              .icon {
                display: inline;
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        .input-box {
          width: 100%;
          padding: 0 5px;
          box-sizing: border-box;
          .icon {
            width: 16px;
            height: 16px;
            position: absolute;
            left: 7px;
            top: 7px;
          }
        }
        input {
          width: 100%;
          height: 30px;
          outline: none;
          border: none;
          border-bottom: 1px solid #dcdcdc;
          padding-left: 30px;
          // background: transparent;
        }
      }
    }
    .isSelect {
      float: right;
    }
  }
  .box {
    height: 45px;
  }
  .buttom-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45px;
    background: #f4f6f9;
    border-radius: 3px;
    padding: 12px;
    box-sizing: border-box;
    .button {
      cursor: pointer;
      width: 54px;
      height: 25px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      float: right;
      line-height: 25px;
      text-align: center;
      margin-right: 10px;
      font-size: 12px;
    }
    .submit {
      border: none;
      background: #1b5297;
      color: white;
    }
  }
  .el-dialog {
    margin-top: 30px !important;
    .el-dialog__header {
      border-bottom: 2px solid #f0f0f0;
    }
    .el-dialog__title {
      font-size: 16px;
    }
    .el-dialog__body {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

</style>
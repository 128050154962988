<!--
  顶部栏--标题栏
-->
<template>
  <div class="title_Block">
    <!-- 顶部试用日期模块  旧 先隐藏-->
    <div class="on_trial" v-if="false">
      <div class="view_mail" v-if="mailbox">
        <div class="mail">
          <span class="emali_box">
            <svg class="emali_svg" aria-hidden="true">
              <use href="#icon-a-Group355"></use>
            </svg>
          </span>
          <!-- 查看电子邮件，并验证账户。如您所知，您仅可在确认邮件后重新登 -->
          <div class="text_mail">
            {{ $t("front-vew-email-verification") }}
          </div>
        </div>
        <div class="iconx">
          <svg class="iconx_svg" aria-hidden="true">
            <use href="#icon-a-Group90" @click="mailbox = false"></use>
          </svg>
        </div>
      </div>
      <div class="trial_date">
        <div class="wellow">
          <!-- 欢迎试用 -->
          <div class="trial_text">{{ $t("front-welcome-to-try") }}</div>
          <svg class="HI_svg" aria-hidden="true">
            <use href="#icon-Vector"></use>
          </svg>
        </div>

        <div class="remaining_time_box">
          <div class="Days">
            <div class="Days_Remaining">
              <div class="day">
                {{ $t("c88") }}
              </div>
              <div class="time">{{ days }}</div>
            </div>
            <div class="order">
              <!-- 立即订购 -->
              <el-button
                type="warning"
                size="small"
                class="orderNowBtn"
                @click="changeOrderNow(orderNowData)"
                >{{ $t("vue_label_title_ordernow") }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 顶部菜单栏模块 -->
    <div class="topTitle">
      <!-- 引入中间全局搜索子组件 -->
      <el-dialog
        :visible.sync="isShowSearchAlert"
        top="42px"
        :show-close="false"
        width="580px"
      >
          <globalSearch
            :isShowSearchAlert="isShowSearchAlert"
          />
      </el-dialog>
      <div class="avator">
        <!-- 骨架屏 -->
        <Wang-skeleton type="line" :options="btnOptions" v-if="frontLoading">
        </Wang-skeleton>
        <div
          v-if="!frontLoading && !Partnercloud && !hideIconFlag"
          class="edit-menus"
          v-show="editOrNewBtn"
          @click="editmenusfu"
        >
          {{ $t("c361") }}
        </div>
        <!-- 骨架屏 -->
        <Wang-skeleton type="line" :options="lineOptions" v-if="frontLoading">
        </Wang-skeleton>
        <!-- 骨架屏 -->
        <Wang-skeleton type="line" :options="iconOptions" v-if="frontLoading">
        </Wang-skeleton>
        <div v-else class="avator_icon">
          <!-- 搜索按钮 -->
          <div class="select-icon" v-if="!hideIconFlag">
            <div class="border-icon" @click="enterSearchBtn">
              <svg
                aria-hidden="true"
                @mouseover="selectMouseover()"
                @mouseout="selectMouseout()"
                class="small-img"
              >
                <use fill="#FFF" href="#icon-search1"></use>
              </svg>
            </div>
          </div>
          <!--      快速新建-->
          <el-dropdown trigger="click" v-show="true && !hideIconFlag">
            <div class="border-icon" v-show="editOrNewBtn">
              <div
                class="quick-add"
                @mouseover="quickAddMouse('over')"
                @mouseout="quickAddMouse('out')"
              >
                <svg class="small-img" aria-hidden="true">
                  <use fill="#FFF" href="#icon-add2"></use>
                </svg>
              </div>
            </div>
            <el-dropdown-menu
              slot="dropdown"
              class="quickNewDropdown"
              style="max-height: 300px; overflow-y: auto"
            >
              <el-dropdown-item
                class="quick-add-hoverback"
                v-for="(item, index) in showList"
                :key="index"
                @click.native="selectItem(item)"
                v-show="item.obj_id !== 'task'"
                style="display: flex; align-items: center; padding: 0 20px"
              >
                <svg class="quickCreate" aria-hidden="true">
                  <use :href="item.iconSrc || item.tabStyle || hometab"></use>
                </svg>
                <span
                  style="
                    width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.tabName }}
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 新增/修改记录 -->
          <create-edit-obj
            ref="createEditObj"
            :prefix="prefix"
            :objectApi="objectApi"
            :object-name="typeName"
          >
          </create-edit-obj>

          <!-- 伙伴云钱包设置 -->
          <div
            v-if="Partnercloud && isOpenAccount && !hideIconFlag"
            @click="openDetail"
            class="accountEnter"
          >
            <svg
              v-show="accountLogo"
              @mouseover="changeAccountLogo"
              class="accountEnterImg"
              aria-hidden="true"
            >
              <use xlink:href="#icon-accountEnter"></use>
            </svg>
            <svg
              v-show="!accountLogo"
              @mouseout="changeAccountLogo"
              class="accountEnterImg"
              aria-hidden="true"
            >
              <use xlink:href="#icon-accountEnterHover"></use>
            </svg>
          </div>
          <!-- 通知中心 -->
          <!-- :unreadCount="unreadCount" :isMore="isMore"  -->
          <NotificationCenter v-if="isRouterAlive && !hideIconFlag" />
          <!-- 如果当前登陆人不是系统管理员-显示此按钮 -->
          <div
            class="seeting"
            @click="lightningSetting"
            v-if="isAdmin && AdminId !== 'aaa000001' && !Partnercloud"
          >
            <span class="el-dropdown-link" v-if="showOldSetting">
              <svg
                class="small-img"
                aria-hidden="true"
                @mouseover="changeSeetingSelect"
                @mouseout="changeSeetingSelectP"
              >
                <use fill="#FFF" href="#icon-setting1"></use>
              </svg>
            </span>
          </div>
          <!-- 新设置入口 -->
          <span class="el-dropdown-link" v-if="showNewSetting && !hideIconFlag">
            <svg
              class="small-img"
              aria-hidden="true"
              @click="lightningSettingNew"
              @mouseover="changeSeetingSelect"
              @mouseout="changeSeetingSelectP"
            >
              <use fill="#FFF" href="#icon-lightning-setup"></use>
            </svg>
          </span>
          <!-- 商城入口 -->
          <!-- <span class="el-dropdown-link">
            <svg
              class="small-img"
              aria-hidden="true"
              @click="openStore()"
              @mouseover="changeSeetingSelect"
              @mouseout="changeSeetingSelectP"
            >
              <use fill="#FFF" href="#icon-store1"></use>
            </svg>
          </span> -->
          <!-- 如果当前登陆人为系统管理员-显示此悬浮窗 -->
          <div v-if="AdminId == 'aaa000001' && !hideIconFlag && showOldSetting">
            <!-- <el-dropdown trigger="click"> -->
            <span class="el-dropdown-link">
              <svg
                class="small-img"
                aria-hidden="true"
                @click="lightningSetting"
                @mouseover="changeSeetingSelect"
                @mouseout="changeSeetingSelectP"
              >
                <use fill="#FFF" href="#icon-setting1"></use>
              </svg>
            </span>
          </div>
          <!-- 帮助中心 -->
          <el-popover
            placement="bottom"
            width="280"
            trigger="click"
            popper-class="helpPopover"
          >
            <div class="help" slot="reference" v-if="!hideIconFlag">
              <div class="border-icon">
                <svg aria-hidden="true" class="small-img">
                  <use fill="#FFF" href="#icon-menu-help"></use>
                </svg>
              </div>
            </div>
            <div class="help-box" v-if="!hideIconFlag">
              <!-- 版本帮助使用盒子 -->
              <div class="help-with">
                <div>
                  <!-- lightning版本使用与帮助 -->
                  {{ $t("vue_label_helpcenter_topic") }}
                </div>
              </div>
              <!-- 固定的中间可以切换的内容 -->
              <div class="help-content" v-show="isShowDetails">
                <!-- 固定的 -->
                <div class="question-mark">
                  <div class="lig">
                    <!-- 什么是lightning? -->
                    {{ $t("vue_label_help_whatslightning") }}
                  </div>
                  <div class="guide" @click="helpinfo">
                    <!-- 查看lightning版本使用指南 -->
                    {{ $t("vue_label_help_viewusageguide") }}
                  </div>
                </div>
                <!-- 可以切换的 -->
                <div class="page-help-box">
                  <div class="page-help">
                    <!-- 标题：该页面上的帮助 -->
                    {{ $t("vue_label_help_helponthispage") }}
                  </div>
                  <div class="page_help_contents">
                    <!-- 对象名称 -->
                    <div class="switch-content" @click="jumpHelpDetails">
                      {{ helpCenterNamne }}
                    </div>
                    <div
                      class="record-details"
                      v-show="isShowRecord"
                      @click="clickRecordDetails"
                    >
                      <!-- 记录详情 -->
                      {{ $t("vue_label_help_detail") }}
                    </div>
                    <div
                      class="list-view"
                      v-show="isShowView"
                      @click="clickListView"
                    >
                      <!-- 列表视图 -->
                      {{ $t("label.search.listview") }}
                    </div>
                    <div
                      class="list-view"
                      v-if="isShowNavigation"
                      @click="globalNavigation"
                    >
                      <!-- 全局导航 -->
                      {{ $t("vue_label_help_golbal") }}
                    </div>
                    <div class="list-view" @click="clickGroupStrategy">
                      <!-- 群策 -->
                      {{ $t("label_mobile_buttom_team") }}
                    </div>
                  </div>
                </div>
                <!-- 底部查看帮助中心 -->
                <div class="help-bottom">
                  <div @click="viewHelpCenter" class="viewHelpCenterClass">
                    <!-- 查看CloudCC帮助中心 -->
                    {{ $t("vue_label_help_viewhelpcenter") }}
                  </div>
                  <div class="help-bottom_add" v-if="isCountryCode">
                    <div>
                      <svg class="icon lightningHelp-img" aria-hidden="true">
                        <use href="#icon-lightningHelp"></use>
                      </svg>
                    </div>
                    <div v-if="SUPPORT_INFO">{{ SUPPORT_INFO }}</div>
                    <div @click="zeroDistanceCommunication" v-else>
                      <!-- 通过启动在线支持请求节约时间，与CloudCC服务专家零距离沟通 -->
                      {{ $t("vue_lightning_help_zero_distance_communication") }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 点击跳转的盒子页面初始化为false -->
              <div class="details-box" v-show="isdetailView">
                <div class="details-content">
                  <!-- 返回上一级 -->
                  <div class="click-back" @click="returnHelp">
                    <!-- 返回上一级 -->
                    {{ $t("vue_label_help_back") }}
                  </div>
                  <!-- 对象详情内容 -->
                  <div class="object-details">{{ detailView }}</div>
                  <div
                    class="details-data"
                    v-html="detailData ? detailData : null"
                  >
                    <!-- 跳转数据 -->
                  </div>
                </div>
                <div class="details-bottom">
                  <div @click="viewHelpCenter">
                    <!-- 查看CloudCC帮助中心 -->
                    {{ $t("vue_label_help_viewhelpcenter") }}
                  </div>
                </div>
              </div>
            </div>
          </el-popover>
          <!-- 搜索框关闭按钮 -->
          <div class="close-box" v-show="hideIconFlag && !$store.state.navigationStyle">
            <svg class="close-icon" aria-hidden="true" @click="getCloseSearch">
              <use fill="#FFF" href="#icon-close"></use>
            </svg>
          </div>
        </div>
        <!-- 头像悬浮框 -->
        <el-popover
          placement="bottom"
          width="340"
          height="355"
          :offset="10"
          trigger="click"
          popper-class="personal"
          @show="showOrderTime"
          @hide="hideClass"
        >
          <div class="userDetailed" slot="reference">
            <!-- 骨架屏 -->
            <Wang-skeleton
              type="avatar"
              :options="headOptions"
              v-if="frontLoading"
            >
            </Wang-skeleton>
            <div class="border_img" v-else>
              <img
                :src="oldImgSrc"
                class="avator-img headPortrait"
                :class="{ activeImg: popoverClass }"
              />
            </div>
          </div>
          <!-- 点击头像出悬浮气泡 -->
          <div class="popoClickName">
            <!-- 头部 -->
            <div class="popoClickName_top">
              <div class="top_header">
                <div class="imgborder" @click="personalDetailsPage()">
                  <img :src="oldImgSrc" alt="" />
                </div>
                <div>
                  <div @click="personalDetailsPage()">
                    {{ realName }}
                  </div>
                  <div>
                    <!-- 设置 label.setup -->
                    <!-- 退出 label_tabpage_exitz -->
                    <span class="settings" @click="personalsettings">{{
                      $t("label.setup")
                    }}</span>
                    <span class="quit" @click="quit">{{
                      $t("label_tabpage_exitz")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- 版本切换  label.pc.ui.2018.switch.version -->
            <!-- v-show="isChangeMenu" -->
            <div
              class="version"
              v-if="
                !$dayjs(showButtonTime || '2022-07-28').isBefore(
                  $dayjs(this.orgCreateTime)
                )
              "
            >
              <div class="versionSwitch">
                {{ $t("label.pc.ui.2018.switch.version") }}
              </div>
              <div class="version-item">
                <div
                  v-for="(item, index) in versionItems"
                  :key="index"
                  :class="{ checked: changeIndex == index }"
                  v-show="item.isshow"
                  @click="changeCheck(index)"
                >
                  {{ $t(item.menuItems) }}
                </div>
              </div>
            </div>
            <div
              class="popoClickName_middle"
              v-if="!Partnercloud && this.countryCode !== 'CN'"
            >
              <div class="middle_one">{{ $t("vue_label_title_order") }}</div>
              <div
                class="middle_two"
                v-for="(items, indexs) in packageData"
                :key="indexs"
              >
                <div class="Remaining_time">
                  <!-- 剩余天数 -->
                  {{ items.productCode
                  }}{{ $t("vue_label_title_remainingdays") }}:<span
                    class="day_red"
                    >{{ items.remainDays }}</span
                  >
                  <!-- 天 -->
                  {{ $t("label.login.access.day") }}
                  <span class="reds" @click="changeOrderNow(items)">{{
                    $t("vue_label_title_ordernow")
                  }}</span>
                </div>
              </div>
              <div class="ordernow">
                <!-- 管理订购 -->
                <span v-if="countryCode == 'CN'" @click="orderImmediately">
                  {{ $t("label.payment.manage.order") }}
                </span>
                <span v-else @click="manageSubscriptions">
                  {{ $t("label.payment.manage.order") }}
                </span>
              </div>
            </div>
            <!-- 其他 label.ems.other-->
            <!-- 回收站 label.recycle -->
            <!-- 导入  label.tolead -->
            <div class="othering" v-if="!Partnercloud">
              <div
                class="other"
                v-if="
                  !$dayjs(showButtonTime || '2022-07-28').isBefore(
                    $dayjs(orgCreateTime)
                  )
                "
              >
                {{ $t("label.ems.other") }}
              </div>
              <div class="line" v-else></div>
              <div class="otherDiv">
                <div class="recycling" @click="recycle">
                  <span>{{ $t("label.recycle") }}</span>
                </div>
                <div class="importing" @click="importing" v-if="isTrue">
                  <span>{{ $t("label.tolead") }}</span>
                </div>
                <div
                  class="systemlog"
                  @click="toSystemlog"
                  v-if="AdminId == 'aaa000001'"
                >
                  <span>{{
                    $t("label.customcomponent.page.index.console.btn")
                  }}</span>
                </div>
                <!-- 我要吐个槽 -->
                <!-- <div
                  class="systemlog"
                  @click="tucao"
                  v-if="
                    userInfo &&
                    ['szyd', 'c0566eaf0c', 'fa4d312eca'].includes(
                      userInfo.data.orgPackage
                    )
                  "
                >
                  <span>{{ $t("c258") }}</span>
                </div> -->
              </div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import {
  // queryPartnerFund,
  // queryPartnerFundSetup,
  getUserVersionInfo,
  logout,
  todayTaskPushMessage,
  getImportPermission,
  getNewAuth,
  seachApplicationTab,
  getUserInfo,
} from "./api.js";
import globalSearch from "@/views/GlobalSearch/globalSearch.vue";
import VueCookies from "vue-cookies";
import * as appStore from "./api.js";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  components: {
    createEditObj: () =>
      import("@/views/commonObjects/components/create-edit-obj.vue"),
    NotificationCenter: () => import("./notification-center.vue"),
    globalSearch,
  },
  props: {
    allmenulist: {
      type: Array,
      default: () => [],
    },
    frontLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 是否显示新版设置按钮
      showNewSetting: false,
      // 是否显示旧版设置按钮
      showOldSetting: true,
      // 是否显示横纵版切换按钮的时间节点，大于这个时间的不显示切换按钮
      showButtonTime: window.Glod.ORG_CREATE_TIME,
      // 组织机构创建时间
      orgCreateTime: "2022-07-28",
      selectMouseoverflag: false, //搜索按钮悬浮样式
      // 是否显示在线客户的配置按钮
      SUPPORT_INFO: window.Glod.SUPPORT_INFO,
      hometab: "#icon-hometab",
      // 伙伴账户/返利账户  是否启用二者之一
      isOpenAccount: false,
      accountLogo: true,
      // Cn展示
      isCountryCode: false,
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      id: "",
      isTrue: false,
      isRouterAlive: true,
      oldImgSrc: "",
      userUrlImg: "",
      // 默认Lightning菜单
      changeIndex: 2,
      // 版本切换
      // 横向菜单 label.top.changeVersion
      // 纵向菜单 label.pc.ui.2018
      // Lightning菜单 label.toptitle.lightning
      versionItems: [
        { menuItems: "label.top.changeVersion", isshow: true },
        { menuItems: "label.pc.ui.2018", isshow: true },
        { menuItems: "label.toptitle.lightning", isshow: true },
      ],
      // 剩余订购时间--默认为0
      orderTime: 0,
      // 未读消息为0时隐藏
      // isMore: true,
      prefix: "",
      objectApi: "",
      //是否是管理员
      isAdmin: false,
      AdminId: "",
      typeName: "",
      // 点击跳转详情
      isShowDetails: true, //默认页面
      isdetailView: false, //点击跳转后有返回上一级的页面
      // 详情页视图数据名称
      detailView: "",
      // 详情页数据
      detailData: "",
      // 是否显示记录详情
      isShowRecord: false,
      // 是否显示列表视图
      isShowView: false,
      // 是否显示全局导航
      isShowNavigation: false,
      allAddList: [],
      isHelp: false,
      isSeeting: false,
      userurl: "",
      popoverClass: false,
      usernameId: "",
      realName: "",
      countryCode: "",
      // 相关人员
      // 帮助中心名称
      helpCenterNamne: "", //标题名称
      isShowhelpCenterNamne: false,
      //
      specialVariables: "",
      quickAddMouseStatus: "out", // 快速新建图标状态
      mailbox: false, //顶部邮箱是否验证
      remaining: false, //获取剩余天数
      days: "", //天数
      packageData: [],
      orderNowData: {},
      // 立即订购需要的cookie
      cookieData: "",
      userInfo: {}, //用户信息
      isShowSearchAlert: false, //搜索弹框
      hideIconFlag: false, //是否隐藏图标
      btnOptions: {
        row: 1,
        column: 1,
        width: "60px",
        height: "14px",
        backgroundColor: "#3C465C",
      },
      iconOptions: {
        row: 1,
        column: 6,
        width: "20px",
        height: "20px",
        backgroundColor: "#3C465C",
      },
      headOptions: {
        row: 1,
        column: 1,
        width: "20px",
        height: "20px",
        backgroundColor: "#3C465C",
      },
      lineOptions: {
        row: 1,
        column: 1,
        width: "2px",
        height: "30px",
        backgroundColor: "#3C465C",
      },
      editOrNewBtn: true, // 判断编辑菜单和快速新建展示
    };
  },
  watch: {
    // 监听菜单栏切换变化
    tabNameData() {
      // 选择的菜单
      this.isdetailView = false;
      this.isShowDetails = true;
    },

    // 监听页面路由变化
    $route: {
      handler: function (route) {
        // 判断编辑菜单和快速新建展示
        if (
          route.path.indexOf("systemSettings") == 1 ||
          route.path.indexOf("print-") == 1
        ) {
          this.editOrNewBtn = false;
        }
        if (route.params.oprateType === "DETAIL") {
          //详情
          this.isShowNavigation = false; //是否显示全局导航
          this.isShowRecord = true; //是否显示记录详情
          this.isShowView = false; //是否显示列表视图
        } else if (route.params.type === "table") {
          //列表
          this.isShowNavigation = false;
          this.isShowView = true;
          this.isShowRecord = false;
        } else if (
          route.name === "clientPool" &&
          (route.params.objectApi === "Lead" ||
            route.params.objectApi === "cloudcclead")
        ) {
          this.tabNameData = this.$i18n.t("vue_label_pool_poolname"); //潜在客户
          this.isShowNavigation = false;
          this.isShowView = true;
          this.isShowRecord = true;
        } else if (
          route.name === "clientPool" &&
          route.params.objectApi === "Account"
        ) {
          this.tabNameData = this.$i18n.t("label.weixin..xsld.kehu"); //客户
          this.isShowNavigation = false;
          this.isShowView = true;
          this.isShowRecord = true;
        } else {
          this.isShowView = false;
          this.isShowRecord = false;
        }
        // Account：客户，Lead：潜在客户，Contact：联系人，Opportunity：业务机会，campaign：市场活动。quote：报价单。contract：合同，
        // cloudccskjh：收款计划。product：产品。cloudcccase：个案
        if (
          route.params.objectApi === "Account" &&
          route.name !== "clientPool"
        ) {
          this.specialVariables = "";
          this.helpCenterNamne = this.$i18n.t("label.mr.object.customer");
          this.isShowNavigation = false;
        } else if (
          (route.params.objectApi === "Lead" ||
            route.params.objectApi === "cloudcclead") &&
          route.name !== "clientPool"
        ) {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t(
            "label_tabpage_potentialcustomersz"
          );
        } else if (route.params.objectApi === "Contact") {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label_tabpage_contactsz");
        } else if (route.params.objectApi === "Opportunity") {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t(
            "lable.quote.syncing.opportunity"
          );
        } else if (route.params.objectApi === "campaign") {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.marketingactivity");
        } else if (route.params.objectApi === "quote") {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("lable.quote.syncing.quote");
        } else if (route.params.objectApi === "contract") {
          //合同
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.contract");
        } else if (route.params.objectApi === "cloudccskjh") {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.Receivables"); //收款计划
        } else if (route.params.objectApi === "product") {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.product"); //产品
        } else if (route.params.objectApi === "cloudcccase") {
          //个案
          this.specialVariables = "";
          this.helpCenterNamne = this.$i18n.t("label.case");
          this.isShowNavigation = false;
        } else if (route.path === "/priceManual/price-manual") {
          this.specialVariables = "";
          //价格手册
          this.helpCenterNamne = this.$i18n.t("label.product.pricebooks"); //价格手册
        } else if (route.path === "/targetModel/index") {
          this.specialVariables = "";
          //目标模型
          this.helpCenterNamne = this.$i18n.t("label_target_model"); //目标模型
        } else if (route.path === "/target/index") {
          this.specialVariables = "";
          //目标
          this.helpCenterNamne = this.$i18n.t(
            "component.salesperformance.label.target"
          ); //目标
        } else if (route.path === "/homePageObject/standardPage") {
          this.specialVariables = "";
          //标准主页
          this.isShowNavigation = true;
          this.isShowView = false;
          this.helpCenterNamne = this.$i18n.t(
            "label_profile_mobile_setup_stand"
          );
        } else if (route.path === "/reportObject/reportList") {
          this.specialVariables = "";
          //报表
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.visualpage.btype");
        } else if (route.path === "/dashboardObject/dashBoardList") {
          this.specialVariables = "";
          //仪表板
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.dashboard");
        } else if (route.path === "/CalendarHeader") {
          this.specialVariables = "";
          //日历
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.calendar");
        } else if (route.path === "/ccFileCenter/ccFileList") {
          this.specialVariables = "";
          //文件
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label_chatter_file");
        } else if (
          (route.params.objectApi === "Lead" ||
            route.params.objectApi === "cloudcclead") &&
          route.name === "clientPool"
        ) {
          this.specialVariables = this.$i18n.t("vue_label_leadpool");
          //潜在客户公海池
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("vue_label_leadpool");
          if (
            route.params.objectApi === "Lead" ||
            route.params.objectApi === "cloudcclead"
          ) {
            this.isShowRecord = false; //是否显示记录详情
            this.isShowView = true; //是否显示列表视图
          } else if (route.params.oprateType === "DETAIL") {
            this.isShowRecord = true; //是否显示记录详情
            this.isShowView = false; //是否显示列表视图
          }
        } else if (
          route.params.objectApi === "Account" &&
          route.name === "clientPool"
        ) {
          this.specialVariables = this.$i18n.t("vue_label_accountpool");
          //客户公海池
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("vue_label_accountpool");
          if (route.params.objectApi === "Account") {
            this.isShowRecord = false; //是否显示记录详情
            this.isShowView = true; //是否显示列表视图
          } else if (route.params.oprateType === "DETAIL") {
            this.isShowRecord = true; //是否显示记录详情
            this.isShowView = false; //是否显示列表视图
          }
        } else if (route.path === "/relation" || route.path === "/writeEmail") {
          this.specialVariables = this.$i18n.t("label.ems.emails");
          //邮件
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.ems.emails");
        } else if (route.path === "/noteSms/noteSmsList") {
          this.specialVariables = this.$i18n.t("label.setupsms");
          //短信
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t("label.setupsms");
        } else if (route.path === "/approvalCenter/approvalList") {
          //审批中心
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = this.$i18n.t(
            "homepage_componet_approval_label"
          );
        } else {
          this.specialVariables = "";
          this.isShowNavigation = false;
          this.helpCenterNamne = "";
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // 判断是否显示新版设置菜单
    this.showNewSetting =
      "1" == window.Glod["NEW_SETUP_OPEN"] ||
      "2" == window.Glod["NEW_SETUP_OPEN"] ||
      window.Glod["NEW_SETUP_OPEN_ORG"]?.indexOf(
        this.$CCDK.CCUser.getUserInfo().orgId
      ) != -1;
    // 判断是否显示旧版设置菜单
    this.showOldSetting =
      "0" == window.Glod["NEW_SETUP_OPEN"] ||
      "1" == window.Glod["NEW_SETUP_OPEN"];
    // 如果是伙伴云才请求接口
    if (this.Partnercloud) {
      this.getPartnerFundSetup();
    }
    //是否为伙伴云用户
    if (this.Partnercloud == true) {
      this.versionItems[1].isshow = false;
    }
    this.userurl = this.$cookies.get("domainName");
    //监听点击搜索菜单
    this.$Bus.$on("enterSelectBtn", this.ebFn2);
    //监听关闭搜索菜单
    this.$Bus.$on("closeSelectBtn", this.ebFn3);
    // 监听菜单栏搜索功能
    this.$bus.$on("completesearch", this.ebFn4);
    // 今天到期或者逾期的任务消息推送
    this.todayTaskPushMessageMethod();
  },
  destroyed() {
    this.$Bus.$off("enterSelectBtn", this.ebFn2);
    this.$Bus.$off("closeSelectBtn", this.ebFn3);
    this.$bus.$off("completesearch", this.ebFn4);
    this.$bus.$off("uploadPicture", this.ebFn5);
  },
  created() {
    this.getOrgCreatetimeByOrgI();
    this.isCountryCode = VueCookies.get("countryCode") === "CN";
    this.getIstrue();
    this.getUserName();

    this.isdetailView = false;
    this.getMenuList();
    this.$bus.$on("uploadPicture", this.ebFn5);
  },
  computed: {
    showList: function () {
      let arr = this.allmenulist.filter(
        (item) => item.type === "object" && item.isquickcreated === "true"
      );
      arr.map((item) => {
        if (item.tabStyle && item.tabStyle.indexOf("#icon-") === -1) {
          item.tabStyle = "#icon-" + item.tabStyle;
        }
      });
      return arr;
    },
    // tabNameData() {
    //   // 客户，潜在客户，联系人，业务机会，市场活动，报价单，合同,收款计划,产品，价格手册，个案

    //   return this.$store.state.tabName;
    //   // 选择的菜单标准对象名称
    // },

    // 是否放出版本菜单切换
    // isChangeMenu() {
    //   return this.$store.state.userInfoObj.profileId === "aaa000001" ||
    //     this.$store.state.userInfoObj.orgId !== "2010000000050855WyHh"
    //     ? true
    //     : false;
    // },
  },
  methods: {
    ebFn2() {
      if (!this.$store.state.navigationStyle) {
        this.hideIconFlag = true;
      }
    },
    ebFn3() {
      this.hideIconFlag = false;
    },
    ebFn4() {
      this.isShowSearchAlert = false;
    },
    ebFn5() {
      this.oldImgSrc = `${VueCookies.get(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=${
        this.usernameId
      }&binding=${this.$CCDK.CCToken.getToken()}&wj=${Math.random()}`;
    },
    // 关闭搜索按钮
    getCloseSearch() {
      this.$Bus.$emit("closeSelectBtn");
      this.hideIconFlag = false;
      this.$router.push({
        path: this.$store.getters.getFromGlobalSearchPath,
      });
    },
    /**
     * 获取组织创建时间
     */
    getOrgCreatetimeByOrgI() {
      appStore.getOrgCreatetimeByOrgId().then((res) => {
        if (res && res.data) {
          this.orgCreateTime = res.data || "2022-07-28";
        }
      });
    },
    /**
     * 跳转到新版设置页面
     */
    lightningSettingNew() {
      window.open(
        `${window.Glod.SETUP_URL}/#/setting`
      );
    },
    /**
     * 打开商店
     */
    openStore() {
      window.open(`http://121.40.30.69:8005/#/home/allApps`);
    },
    // 搜索按钮
    selectMouseover() {
      this.selectMouseoverflag = true;
    },
    selectMouseout() {
      this.selectMouseoverflag = false;
    },
    // 编辑菜单按钮
    editmenusfu() {
      this.$Bus.$emit("sendEditMenus");
    },
    // 验证是否是管理员
    administratorPrivileges() {
      appStore.getAdministrators().then((res) => {
        if (res.data.isAdmin === true) {
          // this.remaining = true;
          this.mailboxVerification();
          this.getRemainingTime(res.data.isAdmin);
        } else if (res.data.status === false) {
          this.remaining = false;
        }
      });
    },
    // 邮箱是否验证
    mailboxVerification() {
      // 页面顶部显示邮箱是否验证
      appStore.getMailboxVerification().then((res) => {
        if (res.data.status === "1") {
          this.mailbox = false;
        } else if (res.data.status === "0") {
          this.mailbox = true;
        }
      });
    },
    // 获取剩余天数
    getRemainingTime(isAdmin) {
      // res.data.remainDays === -1 顶部试用日期不显示
      appStore.getDaysRemaining().then((res) => {
        if (res.data.remainDays !== -1) {
          this.days = res.data.remainDays;
          if (isAdmin) {
            this.remaining = true;
          }
        } else if (res.data.remainDays === -1) {
          this.remaining = false;
        }
      });
    },
    // 获取套餐剩余天数
    changePackageDays() {
      appStore.getPackageDays().then((res) => {
        this.packageData = res.data.isLoading;
        this.orderNowData = this.packageData[0];
      });
    },
    // 头像下立即订购
    changeOrderNow(val) {
      // var local = localStorage.getItem("locale");
      let cook = document.cookie.replace(/=/g, ":");
      var cookList = cook.split(";");
      //
      cookList.forEach((item) => {
        let kie = item.split(":");
        //
        if (kie[0] == " binding") {
          this.cookieData = kie[1];
        }
      });
      window.open(
        `${window.Glod.APP_STORE_URL}/#/home/store?product=` +
          val.productCode +
          "&" +
          "v=" +
          val.versionCode +
          "&" +
          "binding=" +
          this.cookieData +
          "&U=" +
          window.Glod["ccex-apitsf"] +
          "/api"
      );
    },
    // 管理订购
    manageSubscriptions() {
      localStorage.getItem("locale");
      let cook = document.cookie.replace(/=/g, ":");
      var cookList = cook.split(";");
      cookList.forEach((item) => {
        let kie = item.split(":");
        if (kie[0] == " binding") {
          this.cookieData = kie[1];
        }
      });
      window.open(
        `${window.Glod.APP_STORE_URL}/#/home/Purchased?binding=` +
          this.cookieData +
          "&U=" +
          window.Glod["ccex-apitsf"] +
          "/api"
      );
    },
    // 点击搜索按钮
    enterSearchBtn() {
      this.isShowSearchAlert = true;
    },
    // 快速新建鼠标事件
    quickAddMouse(flag) {
      this.quickAddMouseStatus = flag;
    },
    // 查询伙伴账户设置  来判断是否显示伙伴云钱包logo
    // 获取伙伴账户启用设置
    async getPartnerFundSetup() {
      let result = await appStore.ispartnerwallet();
      if (result.returnCode === "10000") {
        this.isOpenAccount = true;
      } else {
        this.isOpenAccount = false;
      }
    },
    openDetail() {
      this.$router.push({
        path: `/partnerAccount/detail`,
      });
    },
    changeAccountLogo() {
      this.accountLogo = !this.accountLogo;
    },
    //判断是否显示导入按钮
    async getIstrue() {
      let res = await getImportPermission(localStorage.binding);
      this.isTrue = res.data === "true";
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 今天到期或者逾期的任务消息推送
    async todayTaskPushMessageMethod() {
      let params = {};
      await todayTaskPushMessage(params);
    },

    // 获取当前用户信息
    async getUserName() {
      let res = await getUserInfo();
      this.usernameId = res.data.userId;
      this.AdminId = res.data.profileId;
      this.realName = res.data.userName;
      this.isAdmin = JSON.parse(res.data.manageSetup);
      this.oldImgSrc = `${VueCookies.get(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=${
        this.usernameId
      }&binding=${this.$CCDK.CCToken.getToken()}`;
      this.countryCode = res.data.countryCode;
    },
    // 版本切换
    changeCheck(index) {
      this.changeIndex = index;
      //清除sessionStorage存储的应用程序id及选项卡id
      sessionStorage.removeItem("application");
      sessionStorage.removeItem("applicationTab");
      if (index == 0) {
        window.open(
          `${
            this.userurl
          }/changeVersion.action?version=version72&binding=${this.$CCDK.CCToken.getToken()}`
        );
      } else if (index == 1) {
        window.open(
          `${
            this.userurl
          }/changeVersion.action?version=version2018&binding=${this.$CCDK.CCToken.getToken()}`
        );
      } else if (index == 2) {
        this.$router.push({
          path: `/`,
        });
      }
    },

    // Lighting菜单版本跳转
    // LightningJump() {
    //   this.$router.go(0);
    // },

    // 获取立即订购剩余时间
    async showOrderTime() {
      this.popoverClass = true;
      this.changeIndex = 2;
      let params = {};
      let orderTimeRes = await getUserVersionInfo(params);
      this.orderTime = orderTimeRes.data.dayLeft;
      // 仅可见 Lighting菜单  版本
      // this.lightingMenuing();
    },
    // 头像悬浮选中状态
    hideClass() {
      this.popoverClass = false;
    },
    // 点击记录详情跳转
    clickRecordDetails() {
      this.isShowDetails = !this.isShowDetails;
      this.isdetailView = !this.isdetailView;
      this.detailView = this.$i18n.t("vue_label_help_detail"); //点击列表视图跳转标题名称
      this.detailData = this.$i18n.t("vue_label_helpcenter_detail"); //跳转后内容
    },
    // 点击列表视图跳转
    clickListView() {
      this.isShowDetails = !this.isShowDetails;
      this.isdetailView = !this.isdetailView;
      this.detailView = this.$i18n.t("label.search.listview");
      this.detailData = this.$i18n.t("vue_label_helpcenter_view1");
    },
    // 全局导航
    globalNavigation() {
      this.isShowDetails = !this.isShowDetails;
      this.isdetailView = !this.isdetailView;
      this.detailView = this.$i18n.t("vue_label_help_golbal");
      this.detailData = this.$i18n.t("vue_label_helpcenter_all");
    },
    // 点击群策
    clickGroupStrategy() {
      this.isShowDetails = !this.isShowDetails;
      this.isdetailView = !this.isdetailView;
      this.detailView = this.$i18n.t("label_mobile_buttom_team");
      this.detailData = this.$i18n.t("vue_label_helpcenter_chat");
    },
    //获取全部菜单
    getMenuList() {
      let filtercalendar = [];
      seachApplicationTab({})
        .then(() => {
          this.allAddList = filtercalendar.filter(
            (item) => item.type === "object"
          );
          this.allAddList = this.baseAddList.concat(this.allAddList);
        })
        .catch(() => {});
    },
    // 查看CloudCC帮助中心
    viewHelpCenter() {
      if (localStorage.getItem("locale") == "zh") {
        let routeUrl = this.$router.resolve({
          path: "/help",
        });
        window.open(routeUrl.href, "_blank");
      } else {
        window.open("https://help-en.cloudcc.com/en");
      }
    },
    //通过启动在线支持请求节约时间，与CloudCC服务专家零距离沟通
    zeroDistanceCommunication() {
      window.open(
        `${window.Glod['ccex-online']}/#/index/mainLogin?accessToken=${this.$CCDK.CCToken.getToken()}`
      );
    },
    // 点击跳转帮助详情
    jumpHelpDetails() {
      this.isShowDetails = !this.isShowDetails;
      this.isdetailView = !this.isdetailView;
      if (this.specialVariables == "") {
        this.originalMenuBar(this.helpCenterNamne);
      } else if (this.specialVariables !== "") {
        this.specialMenuBar(this.specialVariables);
      }
    },
    // 原有的菜单栏跳转
    originalMenuBar(val) {
      if (val === this.$i18n.t("label_profile_mobile_setup_stand")) {
        //主页
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_homepage");
      } else if (val === this.$i18n.t("label.visualpage.btype")) {
        //报表
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_report");
      } else if (val === this.$i18n.t("label.dashboard")) {
        //仪表板
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_dashboard");
      } else if (val === this.$i18n.t("label.calendar")) {
        //日历
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_calendar");
      } else if (val === this.$i18n.t("label_chatter_file")) {
        //文件
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_file");
      } else if (val === this.$i18n.t("homepage_componet_approval_label")) {
        //审批
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_approve");
      } else if (val === this.$i18n.t("label.mr.object.customer")) {
        //客户
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_account");
      } else if (val === this.$i18n.t("label_tabpage_contactsz")) {
        //联系人
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_contact");
      } else if (val === this.$i18n.t("label_tabpage_potentialcustomersz")) {
        //潜在客户
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_lead");
      } else if (val === this.$i18n.t("lable.quote.syncing.opportunity")) {
        //业务机会
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_opportunity");
      } else if (val === this.$i18n.t("label.marketingactivity")) {
        //市场活动
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_campaign");
      } else if (val === this.$i18n.t("lable.quote.syncing.quote")) {
        //报价单
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_quote");
      } else if (val === this.$i18n.t("label.contract")) {
        //合同
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_contract&plan");
      } else if (val === this.$i18n.t("label.Receivables")) {
        //收款计划
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_contract&plan");
      } else if (val === this.$i18n.t("label.product")) {
        //产品
        this.detailView = val;
        this.detailData = this.$i18n.t(
          "vue_label_helpcenter_product&pricebook"
        );
      } else if (val === this.$i18n.t("label.product.pricebooks")) {
        //价格手册
        this.detailView = val;
        this.detailData = this.$i18n.t(
          "vue_label_helpcenter_product&pricebook"
        );
      } else if (val === this.$i18n.t("label.case")) {
        //个案
        this.detailView = val;
        this.detailData = this.$i18n.t("vue_label_helpcenter_case");
      }
    },
    // 特殊的菜单栏
    specialMenuBar(Menu) {
      if (Menu === this.$i18n.t("vue_label_leadpool")) {
        //潜在公海池
        this.detailView = Menu;
        this.detailData = this.$i18n.t("vue_label_helpcenter_pool");
      } else if (Menu === this.$i18n.t("vue_label_accountpool")) {
        //客户公海池
        this.detailView = Menu;
        this.detailData = this.$i18n.t("vue_label_helpcenter_pool");
      } else if (Menu === this.$i18n.t("label.ems.emails")) {
        //邮件
        this.detailView = Menu;
        this.detailData = this.$i18n.t("vue_label_helpcenter_email");
      } else if (Menu === this.$i18n.t("label.setupsms")) {
        //短信
        this.detailView = Menu;
        this.detailData = this.$i18n.t("vue_label_helpcenter_SMS");
      }
    },
    // 返回上一级
    returnHelp() {
      this.isShowDetails = !this.isShowDetails;
      this.isdetailView = !this.isdetailView;
    },
    // 快速新建
    selectItem(e) {
      this.typeName = e.tabName;
      this.objectApi = e.objectApi;
      this.prefix = e.prefix;
      this.id = "";
      if (e.objectApi && e.prefix) {
        getNewAuth({
          id: e.objId,
        })
          .then((res) => {
            if (res.data.add) {
              if (e.isCall) {
                setTimeout(() => {
                  this.$refs.createEditObj.add("", "isCall"); //记录电话传递参数
                }, 300);
              } else {
                setTimeout(() => {
                  this.$refs.createEditObj.add();
                }, 300);
              }
              // this.$refs.createEditObj.add();
            } else {
              this.$message({
                showClose: true,
                message: this.$i18n.t("label.weixin.donot.permission"),
                type: "warning",
              });
            }
          })
          .catch(() => {});
      } else {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label.weixin.donot.permission"),
          type: "warning",
        });
      }
    },

    helpinfo() {
      if (localStorage.getItem("locale") == "zh") {
        window.open(
          "https://cdn.cloudcc.com/cloudcc-pdf/LightningPC%E9%94%80%E5%94%AE%E4%BA%91%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf"
        );
      } else {
        window.open(
          "https://help-en.cloudcc.com/en/articles/5534595-welcome-to-cloudcc"
        );
      }
    },
    seeting() {
      window.open(
        `${
          this.userurl
        }/setupUi2018.action?binding=${this.$CCDK.CCToken.getToken()}`
      );
    },
    //跳转lightning设置
    lightningSetting() {
      const newPage = this.$router.resolve({ path: "/systemSettings" });
      window.open(newPage.href, "_blank");
      // this.$router.push({
      //   path:'/setuppage'
      // })
    },
    changeSeetingSelect() {
      this.isSeeting = true;
    },
    changeSeetingSelectP() {
      this.isSeeting = false;
    },
    changeHelpSelect() {
      this.isHelp = true;
    },
    changeHelpSelectP() {
      this.isHelp = false;
    },
    changeHelp() {
      this.isHelp = false;
    },
    // 点击跳转个人详情
    personalDetailsPage() {
      this.$router.push({
        path: `/commonObjects/detail/${this.usernameId}/DETAIL`,
      });
      this.$bus.$emit("followersTo");
    },
    // 请求退出登录接口
    async quitUser() {
      const res = await logout({});
      this.$CCDK.CCToken.clearToken();
      // 如果返回就用返回地址 ， 没有用默认的
      if (res.data) {
        window.location.href = res.data;
      } else {
        window.location.href = window.Glod.LOGIN_URL;
      }
    },
    //退出
    quit() {
      this.quitUser();
      //清除sessionStorage存储的应用程序id及选项卡id
      sessionStorage.removeItem("application");
      sessionStorage.removeItem("applicationTab");
    },
    // 个人设置
    // `https://solution.cloudcc.com/userselfmodify.action?vr=inno&setupId=PersonalInformation `
    personalsettings() {
      // 是否开启新版设置，0:关闭新版设置，开启旧版设置。1:开启新版设置，开启旧版设置。2：开启新版设置，关闭旧版设置，3：关闭新版设置，关闭旧版设置
      if(window.Glod.NEW_SETUP_OPEN === '1' || window.Glod.NEW_SETUP_OPEN === '2'){
        // 跳转到设置个人设置页面
        const setupUrl = `/${window.Glod.SETUP_URL}/#/settings/profileInfo`;
        window.open(setupUrl, "_blank");
      }else{
        this.$router.push({ name: "profileSetting" });
      }
      
    },
    // 导入
    // /dataManage.action?m=getiefiles&downtype=import&setupId=DataImport&binding=
    // `https://solution.cloudcc.com/uiSetup.action?vr=inno `
    // tolead() {
    //   window.open(
    //     `${
    //       this.userurl
    //     }/uiSetup.action?vr=inno&downtype=import&setupId=DataImport&binding=${VueCookies.get(
    //       "binding"
    //     )}`
    //   );
    // },
    /**
     * 回收站
     */
    recycle() {
      this.$router.push({
        path: "/recycle",
      });
    },
    //导入
    importing() {
      this.$router.push({
        path: `/importModule`,
      });
    },
    /**
     * 跳转到吐槽应用
     */
    tucao() {
      let url = "https://cloudcc.bimo.world/";
      if (window.Glod.tucao) {
        url = window.Glod.tucao;
      }
      window.open(url + "?email=" + this.userInfo.data.loginName);
    },

    /** 跳转到devconsoleweb当前组织的日志 */
    toSystemlog() {
      // 1-获取devconsoleWeb域名
      this.$Axios
        .post(window.Glod["ccex-sysconfig"] + "/auth/domain/getBackEndAddr", {
          accessToken: this.$CCDK.CCToken.getToken(),
        })
        .then((res) => {
          if (res.data) {
            let devWebDomain = res.data.data.devconsoleWebAddress;
            let devWeb = devWebDomain
              ? devWebDomain
              : window.Glod["DEV_CONSOLE_URL"];
            if (window.Glod.ENV_TYPE == "public") {
              // 2-权限判断
              this.$devHttp
                .postFormat(
                  `${window.Glod["ccex-sysconfig"]}/auth/pc/1.0/get/getUserInfo`,
                  {
                    accessToken: this.$CCDK.CCToken.getToken(),
                  }
                )
                .then((res) => {
                  if ("200" == res.returnCode && res.data != null) {
                    if (Object.keys(res.data.function).length == 0) {
                      this.$message.warning(
                        "权限不足，请联系系统管理员；在多组织配置库用户表信息下配置角色，角色ID：role2021B454B8CDBrsfsq"
                      );
                    } else {
                      // 3-有菜单权限跳转到devconsole项目
                      window.open(
                        devWeb +
                          `/#/log/systemLog?accessToken=${
                            this.$CCDK.CCToken.getToken() +
                            "," +
                            this.$i18n.locale
                          }`
                      );
                    }
                  } else {
                    this.$message({
                      message: res.returnInfo,
                      type: "warning",
                    });
                  }
                })
                .catch((error) => {
                  this.$message({
                    message: error,
                    type: "warning",
                  });
                });
            } else {
              window.open(
                devWeb +
                  `/#/log/systemLog?accessToken=${
                    this.$CCDK.CCToken.getToken() + "," + this.$i18n.locale
                  }`
              );
            }
          }
        });
    },
    // 立即订购
    orderImmediately() {
      window.open(
        `https://www.cloudcc.com/sale-cloud/products-salespricing.html `
      );
    },

    // transversemenu() {
    //   window.open(
    //     `${
    //       this.userurl
    //     }/changeVersion.action?version=version72&binding=${VueCookies.get(
    //       "binding"
    //     )}`
    //   );
    // },
    // portraitmenu() {
    //   window.open(
    //     `${
    //       this.userurl
    //     }/changeVersion.action?version=version2018&binding=${VueCookies.get(
    //       "binding"
    //     )}`
    //   );
    // },
    // lightningmenu() {
    //   this.$router.push({
    //     path: `/`,
    //   });
    // },
  },
};
</script>
<style lang="scss">
.personal {
  padding: 0 !important;
}

// 隐藏快速新建气泡上方箭头
.quickNewDropdown .popper__arrow {
  display: none !important;
}
</style>

<style lang="scss" scoped>
// 帮助中心图标位置样式
.help {
  width: 34px;
  text-align: right;
}
.line {
  height: 2px;
  background: #f5f5f5;
}
.quickCreate {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.accountEnter {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
  margin-top: 5px;
}
.accountEnterImg {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.hoverbackground:hover {
  background-color: #f3f2f2;
  color: #006dcc;
}
.quick-add-hoverback:hover {
  background-color: #f3f2f2;
  color: #006dcc;
}
.menutab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  span {
    cursor: pointer;
  }

  span:hover {
    color: #006dcc;
  }
}
.close-box {
  .close-icon {
    width: 14px;
    height: 14px;
  }
}
.title_Block {
  width: 380px;
  .on_trial {
    width: 100%;
    // height: 140px;
    .view_mail {
      width: 100%;
      height: 35px;
      border: 1px solid #ffb962;
      background: #ffb962;
      line-height: 35px;
      display: flex;
      justify-content: center;
      position: relative;
      .mail {
        font-weight: 400;
        color: #000000;
        font-size: 13px;
        display: flex;
        justify-content: center;
        .emali_box {
          padding: 2px 10px 0px 0;
          .emali_svg {
            width: 20px;
            height: 20px;
            padding-top: 6px;
          }
        }
      }
      .iconx {
        position: absolute;
        font-size: 20px;
        right: 20px;
        .iconx_svg {
          width: 20px;
          height: 20px;
          padding: 6px 10px 0px 0px;
        }
      }
    }
    .trial_date {
      width: 100%;
      height: 65px;
      background: #23416f;
      display: flex;
      justify-content: space-between;
      .wellow {
        display: flex;
      }
      .trial_text {
        font-weight: 500;
        color: #ffffff;
        line-height: 65px;
        font-size: 16px;
        padding-left: 10px;
      }
      .HI_svg {
        width: 22px;
        padding-left: 10px;
      }
      .remaining_time_box {
        width: 30%;
        .Days {
          display: flex;
          width: 100%;
          justify-content: space-evenly;
          .Days_Remaining {
            display: flex;
            .day {
              font-weight: 400;
              color: #ffffff;
              line-height: 65px;
              font-size: 13px;
            }
            .time {
              background: #ffffff;
              font-weight: 400;
              color: #e5851a;
              width: 30px;
              height: 30px;
              border: 1px solid;
              font-size: 16px;
              text-align: center;
              line-height: 30px;
              margin: 17px 0px 0px 10px;
            }
          }
          .order {
            line-height: 65px;
            .orderNowBtn {
              background: #e5851a;
              color: #fff;
            }
            .orderNowBtn:hover {
              background: #ff9520;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .trialDate {
    height: 42px;
    background: #272d43;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
    }
    .occupy {
      width: 350px;
    }
    .mail {
      display: flex;
      .text_mail {
        font-size: 14px;
        font-weight: 400;
        color: #6c98fd;
        margin-left: 8px;
      }
    }
    .mailR {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      .el-button {
        padding: 0;
      }
      .days {
        font-size: 14px;
        font-weight: bold;
        color: #abc4fe;
        span {
          font-size: 16px;
          font-weight: bold;
          color: #fe8a14;
        }
      }
      .orderNowBtn {
        min-width: 80px;
        height: 28px;
        background: #fe8a14;
        border-radius: 4px 4px 4px 4px;
        border: #fe8a14;
        margin: 0 46px 0 55px;
      }
    }
  }
}
.topTitle {
  height: 46px;
  padding: 20px;
  // position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #20233a;
  // 搜索弹框背景透明
  ::v-deep .el-dialog__header {
    padding: 0 !important;
    border: none !important;
  }
  ::v-deep .el-dialog {
    background: transparent;
    box-shadow: none;
    .globalSearch-box {
      display: flex;
    }
    .close-img {
      width: 18px;
      height: 18px;
      margin-left: 12px;
    }
  }
  ::v-deep .el-dialog__body {
    // min-height: 400px;
    padding: 0;
  }
  .username {
    margin-left: 10px;
  }

  .avator {
    .avator_icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 统一处理图标间距
      //  span,div{
      //     margin: 20px;
      //   }
    }
    .edit-menus {
      color: #fff;
      padding-right: 20px;
      border-right: 1px solid #3c465c;
      margin-right: 18px;
      z-index: 1000;
      line-height: 46px;
      white-space: nowrap;
      min-width: 80px;
    }
    .edit-menus:hover {
      color: #abc4fe;
      cursor: pointer;
    }
    .select-icon {
      vertical-align: middle;
      display: flex;
      .border-icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    // position: relative;
    // right: 70px;
    // top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .userDetailed {
      display: flex;
      align-items: center;
      margin-left: 15px;
      .border_img {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
      }
      .headPortrait {
        box-sizing: border-box;
      }
      .name {
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    //  铃铛上面的未读信息数
    // .unreadNumber {
    //   width: 20px;
    //   height: 15px;
    //   position: absolute;
    //   background-color: #ff6600;
    //   border-radius: 10px;
    //   color: white;
    //   font-size: 12px !important;
    //   text-align: center;
    //   line-height: 15px;
    //   right: 172px;
    //   top: 0;
    //   cursor: pointer;
    // }
    .quick-add {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-left: 10px;
      cursor: pointer;
      .quick-add-svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .seeting {
    margin-top: 1px;
  }
  .help {
    margin-top: 3px;
  }
  .avator-img {
    // float: right;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  .activeImg {
    // border: solid 2px #888888;
  }
  .small-img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 1px;
    &:hover {
      background: #585d71;
      use {
        fill: #abc4fe;
      }
    }
  }
  .big-img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 15px;
    width: 24px;
    height: 24px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}
.help-box {
  width: 280px;
  word-break: normal;
  .help-with {
    width: 280px;
    height: 40px;
    border-bottom: 1px solid #cccccc;
    div {
      padding: 10px 0 0 15px;
      color: black;
    }
  }
  .help-content {
    width: 280px;
    border-bottom: 1px solid #cccccc;
    .question-mark {
      width: 280px;
      padding: 11px 16px 0;
      .guide {
        margin: 10px;
        color: #006dcc;
        cursor: pointer;
      }
    }
    .page-help-box {
      width: 280px;
      padding: 0 16px;
      overflow: auto;
      .page_help_contents {
        margin: 10px;
        div {
          margin-bottom: 5px;
        }
        .switch-content {
          color: #006dcc;
          cursor: pointer;
        }
        .record-details {
          color: #006dcc;
          cursor: pointer;
        }
        .list-view {
          color: #006dcc;
          cursor: pointer;
        }
      }
    }
    .help-bottom {
      .viewHelpCenterClass {
        border-top: 1px solid #cccccc;
        color: #006dcc;
        cursor: pointer;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .help-bottom_add {
        border-top: 1px solid #cccccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        .lightningHelp-img {
          width: 40px;
          height: 40px;
        }
        div:first-of-type {
          margin-right: 8px;
        }
        div:last-of-type {
          flex: 1;
          color: #006dcc;
          cursor: pointer;
        }
      }
    }
  }
  // 点击跳转详情的盒子样式
  .details-box {
    width: 280px;
    height: 350px;
    border: 1px solid #cccccc;
    background: #ffffff;
    .details-content {
      width: 280px;
      height: 313px;
      overflow: auto;
      border: 1px solid red($color: #000000);
      .click-back {
        padding: 14px 0 0 22px;
        color: #006dcc;
        cursor: pointer;
      }
      .object-details {
        margin: 10px 0 0 22px;
      }
      .details-data {
        width: 277px;
        height: 221px;
        padding: 5px 33px 0 41px;
        overflow: auto;
        word-break: normal;
      }
      // .details-data::-webkit-scrollbar-thumb{
      //     background-color: rgba(0, 0, 0, .05);
      //     border-radius: 10px;
      //     -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
      // }
    }
    .details-bottom {
      width: 280px;
      border-top: 1px solid #cccccc;
      div {
        padding: 8px 0 0 70px;
        color: #006dcc;
        cursor: pointer;
      }
    }
  }
}

//气泡样式
.popoClickName {
  width: 100%;
  // 头部
  .popoClickName_top {
    padding: 15px 0 20px 15px;
    color: #080707;
    .top_header {
      display: flex;
      align-items: center;
      .imgborder {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          &:hover {
            cursor: pointer;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            padding: 0;
            border: solid 2px #888888;
            box-sizing: border-box;
          }
        }
      }
      div:nth-of-type(2) {
        padding: 1px 100px 0 15px;
        font-size: 16px;
        div {
          color: #333333;
          cursor: pointer;
          padding: 0;
          margin: 0;
          &:nth-of-type(2) {
            padding-top: 3px;
            font-size: 14px;
          }
        }
        .settings {
          &:hover {
            border-bottom: solid 2px #6495ed;
          }
          padding: 0 0 1px 0;
          color: #006dcc;
          cursor: pointer;
        }
        .quit {
          &:hover {
            border-bottom: solid 2px #6495ed;
          }
          padding: 0 0 1px 0;
          color: #006dcc;
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }
  }
  //版本切换
  .version {
    font-size: 12px;
    .versionSwitch {
      color: #666666;
      background: #f5f5f5;
      padding: 8px 20px;
    }
    .version-item {
      padding: 8px 0 8px 0;
      .checked {
        color: #006dcc;
      }
      div {
        padding: 6px 0 6px 20px;
        color: #080707;
        cursor: pointer;
        &:hover {
          background-color: #f3f2f2;
        }
      }
    }
    //仅可看Lighting 菜单样式
    .versionLighting {
      color: #666666;
      padding: 8px 20px;
      cursor: pointer;
      &:hover {
        color: #006dcc;
        // background-color: #f0f8ff;
      }
    }
  }
  //订购
  .popoClickName_middle {
    font-size: 12px;
    padding-bottom: 10px;
    .middle_one {
      color: #666666;
      background: #f5f5f5;
      padding: 6px 20px;
    }
    .middle_two {
      color: #080707;
      padding: 12px 0 6px 20px;
      div:first-child {
        // span {
        //   color: red;
        //   margin-left: 4px;
        // }
      }
    }
    .ordernow {
      padding: 10px 0 0 20px;
      padding-bottom: 1px;
      color: #c12218;
      span {
        &:hover {
          border-bottom: solid 2px #cc0000;
        }
        cursor: pointer;
      }
    }
    .day_red {
      color: red;
      padding: 0 10px;
    }
    .reds {
      color: red;
      cursor: pointer;
      padding-left: 10px;
    }
    .Remaining_time {
      padding-bottom: 10px;
    }
  }

  // 其他
  .othering {
    font-size: 12px;
    .other {
      color: #666666;
      background: #f5f5f5;
      padding: 6px 20px;
    }
    .otherDiv {
      // display: flex;
      .recycling {
        &:hover {
          background-color: #f3f2f2;
          color: #006dcc;
          // border-bottom: solid 2px #6495ed;
        }
        span {
          padding: 0 0 1px 0;
        }
        margin: 8px 0 0 0;
        padding: 10px 0 6px 20px;
        color: #080707;
        cursor: pointer;
      }
      .importing {
        &:hover {
          background-color: #f3f2f2;
          color: #006dcc;
          // border-bottom: solid 2px #6495ed;
        }
        span {
          padding: 0 0 1px 0;
        }
        margin: 5px 0 5px 0;
        padding: 10px 0 6px 20px;
        color: #080707;
        cursor: pointer;
      }
      .systemlog {
        &:hover {
          background-color: #f3f2f2;
          color: #006dcc;
          // border-bottom: solid 2px #6495ed;
        }
        span {
          padding: 0 0 1px 0;
        }
        margin: 5px 0 5px 0;
        padding: 10px 0 6px 20px;
        color: #080707;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.el-popover.helpPopover {
  padding: 0px;
}
</style>

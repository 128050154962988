<template>
  <div class="shuttle-box" style="position: relative">
    <!-- 中间全局搜索框 -->
    <div class="global-search">
      <!-- 右侧搜索框 -->
      <div class="right_search">
        <el-popover
          v-model="showResult" 
          popper-class="searchRelecently"
          placement="bottom-start"
          width="350"
          style="left: 170px; border: 0"
          trigger="focus"
        >
          <div class="recentlySearch">{{ $t("c1957") }}</div>
          <div
            style="cursor: pointer"
            @click="choseRecord(index, item)"
            class="searchResultItem"
            v-for="(item, index) in filterList"
            :key="index"
            v-html="
              !(
                transferObj.searchObjVal ===
                $t('label.knowledgebase.knowledge.article')
              )
                ? highLight(
                    item.objname + '--' + item.recordName,
                    transferObj.userInput
                  )
                : highLight(
                    $t('label.knowledgebase.knowledge.article') +
                      '--' +
                      item.name,
                    transferObj.userInput
                  )
            "
          >
            <span
              v-show="
                !(
                  transferObj.searchObjVal ===
                  $t('label.knowledgebase.knowledge.article')
                )
              "
              >{{ item.objname }}--{{ item.recordName }}</span
            >
            <span
              v-show="
                transferObj.searchObjVal ===
                $t('label.knowledgebase.knowledge.article')
              "
            >
              {{
                $t("label.knowledgebase.knowledge.article") + "--" + item.name
              }}</span
            >
          </div>
          <div class="searchFather" slot="reference">
            <!-- 搜索图标 -->
            <div class="searchIcon">
              <svg class="searchcopy_icon" v-show="!checked" aria-hidden="true">
                <use xlink:href="#icon-search1-copy"></use>
              </svg>
            </div>
            <!-- 文本输入框 -->
            <!-- <el-dropdown ref="showMenu" trigger="click"> -->
            <!-- <div> -->
            <el-tag
              size="medium"
              closable
              style="margin-left: 30px"
              :key="tag"
              v-for="tag in dynamicTags"
              :disable-transitions="false"
              type="info"
              @close="handleClose(tag)"
              >{{ tag }}</el-tag
            >
            <input
              @keyup.13="getResult()"
              @focus="getFocus"
              @blur="loseFocus"
              @input="getRencentRecord"
              type="text"
              class="elInput"
              v-model="transferObj.userInput"
              :placeholder="$t('c1788')"
            />
            <!-- 关闭按钮 -->
            <div
              style="cursor: pointer"
              class="el-icon-circle-close closeIcon"
              @click="clearInput"
              v-show="clearinput"
            ></div>
          </div>
        </el-popover>
        <!-- 搜索按钮 -->
      </div>
      <!-- 搜索结果页 -->
      <div class="searchResult" v-show="showResult" v-loading="loadDate">
        <!-- 搜索结果 -->
        <el-popover
          v-if="transferObj.searchObjId == 'cloudcc_k_article'"
          placement="bottom"
          width="400"
          trigger="click"
          v-model="visible"
          @show="show"
        >
          <div>
            <div style="display: flex; justify-content: space-between">
              <h3 style="font-weight: 600">
                {{ $t("label.knowledgebase.advanced.filtering") }}
              </h3>
              <h3
                style="font-weight: 600; color: #006dcc; cursor: pointer"
                @click="Resetfilter"
              >
                {{ $t("label.knowledgebase.reset.filter") }}
              </h3>
            </div>
            <div style="margin-top: 20px">
              <div>{{ $t("label.language") }}</div>
              <el-select
                v-model="langevalue"
                :placeholder="$t('label.emailtocloudcc.select1')"
                style="width: 370px"
              >
                <el-option
                  v-for="item in langeoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div style="margin-top: 20px">
              <div>{{ $t("label.knowledgebase.release.status") }}</div>
              <el-select
                v-model="value"
                :placeholder="$t('label.emailtocloudcc.select1')"
                style="width: 370px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div style="margin-top: 20px; margin-bottom: 20px">
              <div>{{ $t("label.knowledgebase.verification.status") }}</div>
              <el-select
                v-model="textarea"
                multiple
                :placeholder="$t('label.emailtocloudcc.select1')"
                style="width: 370px"
              >
                <el-option
                  v-for="item in verificationoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div style="text-align: right; margin-top: 20px">
              <el-button size="mini" type="text" @click="visible = false">{{
                $t("label.emailsync.button.cancel")
              }}</el-button>
              <el-button type="primary" size="mini" @click="apply">{{
                $t("label.apply")
              }}</el-button>
            </div>
          </div>
          <a
            slot="reference"
            style="margin-left: 20px; color: #006dcc; cursor: pointer"
            >{{ $t("label.knowledgebase.advanced.filtering") }}</a
          >
        </el-popover>
      </div>
      <!-- 左侧触发对象 @click="showObjList"-->
      <div class="object-box">
        <div class="canSearch">{{ $t("vue_label_allsearch_searchable_items") }}：</div>

        <el-dropdown>
          <div class="leftButton">
            <!-- 全部已选对象 -->
            {{
              transferObj.searchObjVal == "all"
                ? $t("vue_label_all_selected_objects") + " "
                : transferObj.searchObjVal
            }}
            <div class="iconPosition">
              <svg class="search_icon" aria-hidden="true">
                <use xlink:href="#icon-button_icon_xiala_cli"></use>
              </svg>
            </div>
          </div>

          <!-- 对象列表页 -->
          <el-dropdown-menu
            style="
              background-color: white;
              max-width: 230px;
              overflow: hidden;
              margin: 1px 0;
            "
            class="objSetting"
          >
            <div
              class="singleObj objTitle"
              style="padding-left: 10px; cursor: default"
            >
              <!-- 可搜索项 -->
              {{ $t("vue_label_allsearch_searchable_items") }}
            </div>
            <div
              class="globalObjTitle divStyle"
              :style="{
                background:
                  transferObj.searchObjVal == 'all' ? '#F5F5F5' : 'white',
                color:
                  transferObj.searchObjVal == 'all' ? '#006DCC' : '#2c3e50',
              }"
            >
              <div class="singleObj objTitle" @click="updateAllObj">
                <!-- 全部已选对象 -->
                {{ $t("vue_label_all_selected_objects") }}
              </div>
              <div
                style="margin: 9px 20px; cursor: pointer"
                class="el-icon-s-tools"
                @click="openBox"
              ></div>
            </div>
            <div
              class="singleObj objTitle divStyle"
              @click="choseObj(index)"
              v-for="(item, index) in selectobj"
              :key="index"
              :style="{
                background:
                  item.tlabel == transferObj.searchObjVal ? '#ECF5FF' : '#fff',
                color:
                  item.tlabel == transferObj.searchObjVal
                    ? '#7ac2fd'
                    : '#333333',
              }"
            >
              {{ item.tlabel }}
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 搜索对象设置L -->
    <el-dialog
      :title="$t('label.global.search.obj')"
      :visible.sync="isSearch"
      width="550px"
      top="10Vh"
      append-to-body
    >
      <shuttle-box
        @changeSearch="changeSearch"
        @deliverName="deliverName"
      ></shuttle-box>
    </el-dialog>
  </div>
</template>

<script>
import {
  getObjectSetting,
  saveObjectSetting,
  getRecentRecords,
  getListFieldInfo,
} from "./api.js";
import shuttleBox from "./object-transfer.vue";
import { queryLanguage } from "./api.js";
import { getGlobalSearchSingleResult } from "./api.js";
export default {
  components: {
    shuttleBox,
  },
  // computed: {
  //   selectobj() {
  //     this.getGloObj();
  //   },
  // },
  data() {
    return {
      objId: "",
      //保存所有的全局对象
      globalobj: [],
      //选中的全局对象
      selectobj: [],
      //搜索图标 关闭按钮的显示
      checked: false,
      clearinput: false,
      //结果页的显示
      showResult: false,

      searchRecord: [],
      isSearch: false,
      //保存记录id
      recordId: "",
      //向子组件传递对象
      transferObj: {
        searchObjVal: "all",
        //保存已选对象对应的id
        searchObjId: "all",
        //保存用户输入的搜索关键词
        userInput: "",
      },
      loadDate: false,
      langeoptions: [],
      langevalue: "",
      options: [
        {
          value: this.$i18n.t("label.knowledgebase.draft"),
          label: this.$i18n.t("label.knowledgebase.draft"),
        },
        {
          value: this.$i18n.t("label.knowledgebase.published"),
          label: this.$i18n.t("label.knowledgebase.published"),
        },
        {
          value: this.$i18n.t("label.archived"),
          label: this.$i18n.t("label.archived"),
        },
      ],
      value: this.$i18n.t("label.knowledgebase.published"),
      textarea: null,
      verificationoptions: [
        {
          value: "Not Validated",
          label: "Not Validated",
        },
        {
          value: "Validated",
          label: "Validated",
        },
      ],
      visible: false,
      dynamicTags: [],
    };
  },
  mounted() {
    let userId = localStorage.getItem("myid");
    this.objId = userId;
    if (!localStorage.getItem("searchObjVal")) {
      this.transferObj.searchObjVal = "all";
    } else if (!localStorage.getItem("searchObjId")) {
      this.transferObj.searchObjId = "all";
    } else {
      this.transferObj.searchObjVal = localStorage.getItem("searchObjVal");
      this.transferObj.searchObjId = localStorage.getItem("searchObjId");
    }
    this.$bus.$on("isClear", this.isClearfn);
    this.getGloObj();
    // this.queryLanguage();
  },
  destroyed() {
    this.$bus.$off("isClear",this.isClearfn);
  },
  computed: {
    filterList() {
      if (
        this.transferObj.searchObjVal ===
        this.$i18n.t("label.knowledgebase.knowledge.article")
      ) {
        if (!this.transferObj.userInput) return this.searchRecord;
        let newArr = this.searchRecord;
        return newArr;
      } else {
        if (!this.transferObj.userInput) return this.searchRecord;
        let newArr = this.searchRecord.filter((res) => {
          let result = new RegExp(this.transferObj.userInput).test(
            res.recordName
          );
          return result;
        });
        if (newArr.length == 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.showResult = false;
        }
        return newArr;
      }
    },
  },
  watch: {
    clearinput(val, newval) {
      if (newval && !this.$route.path.includes("globalsearch")) {
        this.transferObj.userInput = "";
      }
    },
  },
  methods: {
    // $bus性能优化
    isClearfn(val){
      if (val) {
        this.transferObj.userInput = "";
      }
    },
    //选中搜索记录
    choseRecord(index, item) {
      // this.transferObj.userInput=this.filterList[index].recordName;
      if (
        this.transferObj.searchObjVal ===
        this.$i18n.t("label.knowledgebase.knowledge.article")
      ) {
        this.recordId = this.filterList[index].id;
      } else {
        this.recordId = this.filterList[index].recordId;
      }
      this.showResult = false;
      this.clearinput = false;
      if (item.oid == "file") {
        this.$router.push({
          path: "/ccFileCenter/fileDetail",
          query: {
            id: item.recordId,
          },
        });
      } else if (item.oid == "dashboardlightning") {
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${item.recordId}`,
          query: {},
        });
      } else if (item.oid == "lightningreport") {
        this.$router.push({
          path: `/reportObject/editReport/run/${item.recordId}`,
          query: {},
        });
      } else {
        this.$router.push(`/commonObjects/detail/${this.recordId}/DETAIL`);
      }
      // 点击搜索后关闭弹框
      this.$bus.$emit("completesearch");
    },
    choseObj(index) {
      // this.deliverName();
      localStorage.setItem("searchObjVal", this.selectobj[index].tlabel);
      this.transferObj.searchObjVal = localStorage.getItem("searchObjVal");
      localStorage.setItem("searchObjId", this.selectobj[index].objid);
      this.transferObj.searchObjId = localStorage.getItem("searchObjId");
      this.transferObj.userInput = "";
    },
    //为用户输入的内容设置高亮
    highLight(val, keyword) {
      let Reg = new RegExp(keyword, "g");
      if (val) {
        const res = val.replace(
          Reg,
          `<span style="color: #3aa1ff;">${keyword}</span>`
        );
        return res;
      }
    },
    updateAllObj() {
      this.transferObj.searchObjVal = "all";
      localStorage.setItem("searchObjVal", this.transferObj.searchObjVal);
      this.transferObj.userInput = "";
      this.transferObj.searchObjId = "all";
      localStorage.setItem("searchObjId", this.transferObj.searchObjId);
      // this.transferObj.searchObjId = 'all';
    },
    //收起弹出框
    changeSearch() {
      this.isSearch = false;
    },
    deliverName(name) {
      this.selectobj = name;
      this.transferObj.searchObjVal = "all";
      localStorage.setItem("searchObjVal", this.transferObj.searchObjVal);
      this.transferObj.searchObjId = "all";
      localStorage.setItem("searchObjId", this.transferObj.searchObjId);
      // 将用户的搜索对象设置参数传递接口存入数据库
      this.saveObjSetting(name);
    },
    //创建存储用户搜索对象的接口调用方法
    async saveObjSetting(name) {
      //用户设置搜索对象的对应id
      //1.将对象id转换为字符串
      let array = [];
      for (let key in name) {
        //判断当前遍历到的元素 有没有objid属性
        //如果没有  将id属性存入array数组
        if (!name[key].objid) {
          array.push(name[key].id);
        } else {
          array.push(name[key].objid);
        }
      }
      array = array.toString();
      let userObjId = {
        objIds: array,
      };
      await saveObjectSetting(userObjId);
      let newResult = await getObjectSetting();
      this.selectobj = newResult.data.selectedObjList;
    },
    getFocus() {
      this.showResult = true;
      this.loadDate = true;
      //调用获取搜索记录的方法
      this.getRencentRecord();
      this.checked = true;
      this.clearinput = true;
    },
    loseFocus() {
      this.checked = false;
      setTimeout(() => {
        this.showResult = false;
      }, 400);
    },
    clearInput() {
      this.transferObj.userInput = "";
      this.clearinput = false;
    },
    //获取全局对象
    async getGloObj() {
      let result = await getObjectSetting();
      //如果首次登录
      for (var key in result.data.selectedObjList) {
        if (result.data.selectedObjList[key].id == "") {
          result.data.selectedObjList.splice(key, 1);
        }
      }
      if (result.data.selectedObjList.length == 0) {
        this.selectobj = result.data.noSelectedObjList.filter((item) => {
          let rules =
            item.id == "account" ||
            item.id == "contact" ||
            item.id == "opportunity" ||
            item.id == "lead";
          return rules;
        });
      } else {
        this.selectobj = result.data.selectedObjList;
      }
    },
    //获取最近搜索记录
    async getRencentRecord() {
      if (this.transferObj.searchObjVal == "all") {
        let userId = {
          objId: "all",
        };
        let result = await getRecentRecords(userId);
        this.searchRecord = result.data.splice(0, 5);
      } else if (
        this.transferObj.searchObjVal ===
        this.$i18n.t("label.knowledgebase.knowledge.article")
      ) {
        let obj = {
          conditions: "",
          objId: "cloudcc_k_article",
          page: "1",
          pageSize: "10",
          searchKeyWord: this.transferObj.userInput,
          dir: "",
          sort: "",
          language: this.langevalue,
          issueStatus: this.value,
          verifyStatus:
            this.textarea && this.textarea.length > 0
              ? this.textarea.toString()
              : "",
          caseArticleId: "",
        };
        this.keyword = obj.searchKeyWord;
        let result = await getGlobalSearchSingleResult(obj);
        this.searchRecord = result.data.dataList.splice(0, 5);
      } else {
        let userId = {
          objId: this.transferObj.searchObjId,
        };
        let result = await getRecentRecords(userId);
        this.searchRecord = result.data.splice(0, 5).filter((res) => {
          return new RegExp(this.transferObj.searchObjVal).test(res.objname);
        });
      }
      if (
        this.transferObj.searchObjVal !==
        this.$i18n.t("label.knowledgebase.knowledge.article")
      ) {
        if (this.searchRecord.length == 0) {
          this.showResult = false;
        }
      }
      // else {
      //   this.showResult = true;
      // }
      this.loadDate = false;
    },
    //获取单个对象信息及表头信息
    async getSingleInfo() {
      let obj = {
        objId: this.transferObj.searchObjId,
      };
      let result = await getListFieldInfo(obj);
      this.searchRecord = result.data;
    },
    getResult() {
      if (this.transferObj.userInput == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.globalsearch.tip"),
        });
        return false;
      }
      this.checked = false;
      this.clearinput = false;
      this.showResult = false;
      this.$router.push(
        `/globalsearch/${this.transferObj.searchObjId}/${this.transferObj.userInput}`
      );
      // 点击搜索后关闭弹框
      this.$bus.$emit("completesearch");
    },
    openBox() {
      this.isSearch = true;
    },
    // 重置筛选器
    Resetfilter() {
      this.queryLanguage();
      this.textarea = null;
      this.value = this.$i18n.t("label.knowledgebase.published");
    },
    // 标签隐藏
    handleClose(tag) {
      this.queryLanguage();
      this.textarea = null;
      (this.value = this.$i18n.t("label.knowledgebase.published")),
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    apply() {
      if (this.textarea && this.textarea.length > 0) {
        this.dynamicTags = [
          this.$i18n.t("label.knowledgebase.filters", { a: "3" }),
        ];
      } else {
        this.dynamicTags = [
          this.$i18n.t("label.knowledgebase.filters", { a: "2" }),
        ];
      }
      this.visible = false;
    },
    show() {
      this.queryLanguage();
    },
    // 查询语言接口
    async queryLanguage() {
      let params = {
        id: "",
      };
      this.langeoptions = [];
      queryLanguage(params).then((res) => {
        for (let i in res.data.languageList) {
          this.langeoptions.push({
            value: res.data.languageList[i].language,
            label: res.data.languageList[i].language,
          });
          if (res.data.languageList[i].is_default_language == "true") {
            this.langevalue = res.data.languageList[i].language;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.searchRelecently {
  border: 0 !important;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.14);
  padding:0 0 10px 0!important;
}
</style>
<style lang="scss" scoped>
.divStyle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
.resultTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
}
.objTitle {
  cursor: pointer;
  font-size: 12px;
}
::v-deep .objSetting {
  position: absolute;
  left: 0px;
  top: 32px;
  width: 220px;
  z-index: 10;
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
}
.searchResultItem {
  font-size: 14px;
  color: #080707;
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding:10px 16px 0px 16px;
}
.iconPosition {
  position: absolute !important;
  right: 8px;
  top: 1px;
  margin-bottom: 0px;
  position: relative;
}
.selectedColor {
  color: #7ac2fd;
}
.leftButton {
  font-size: 14px;
  width: 136px;
  height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  outline: none;
  border-radius: 2px;
  background: #20233a;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  line-height: 32px;
  color: #ffffff;
}
.leftButton:hover {
  cursor: pointer;
}
.globalObjTitle {
  display: flex;
  justify-content: space-between;
}
.searchFather {
  position: relative;
  // border: 1px solid #d9d9d9;
  border-radius: 0 5px 5px 0;
}
::v-deep .singleObj {
  padding: 7px 6px 7px 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.global-search {
  display: flex;
  height: 32px;
  margin-left:4px;
}
.searchIcon {
  position: absolute;
  left: 7px;
  top: 4px;
}

::v-deep .right_search {
  display: flex;
  color: #fff;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.12);
  .el-popper {
    border: 0;
  }
}
.recentlySearch{
  font-size: 14px;
  font-weight: bold;
  color: #080707;
  width:100%;
  border-bottom: 1px solid #EEEEEE;
  padding:12px 16px;
}
.elInput {
  width: 280px;
  border: none;
  height: 32px;
  padding-left: 30px;
  font-size: 14px;
  background: transparent;
  border-radius: 0px;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 80px;
  color: #bbbbbb;
}
.searchcopy_icon {
  width: 14px;
  height: 14px;
  margin-top: 6px;
}
.search_icon {
  width: 12px;
  height: 12px;
  margin-top: 6px;
}
.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
.searchButton {
  width: 60px;
  height: 32px;
  position: absolute;
  right: -1px;
  top: -1.5px;
  background-color: #1989fa;
  border: none;
  text-align: center;
  line-height: 32px;
  outline: none;
  color: white;
  border-radius: 0px 4px 4px 0px;
}
.searchResult {
  // width: 300px;
  // min-height: 40px;
  // position: absolute;
  // right: 0px;
  // top: 34px;
  // background-color: white;
  // box-shadow: 0px 2px 10px 2px #efefef;
  // margin-top: 0px;
  // margin-left: 0px;
  // z-index: 22222;
  // overflow: hidden;
  // font-size: 14px;
}
.object-box {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: #e1e1e1;
  margin-left: 16px;
  .canSearch{
    min-width:60px;
  }
}
.resultList {
  padding: 15px 0px;
  overflow: hidden;
}
.resultList li {
  margin: 0px 15px;
  text-decoration: none;
  padding: 5px 0px;
  white-space: nowrap;
}
::v-deep .el-dialog {
  // min-height: 300px;
  // max-height: 471px;
  overflow: auto;
}
</style>
